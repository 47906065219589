<template>

<div class="container-bg">
<div>
<div class="container-content">

  <div class="listeDons">

    <div class="search rounded-pill mdi mdi-magnify">
      <input type="text" v-model="search" placeholder="Rechercher">
    </div>

      <div class="top-content">

        <v-dialog v-model="dialogDonation" width="1400">
          <v-card class="invenduPerime">        
            <v-card-text>
              <div class="title py-5 text-center title">
                <h2>Les invendus suivants sont bientôt périmés
                  <br>
                Que souhaitez-vous faire ?
                </h2>
              </div>
              <v-data-table :headers="headersDonation" :items="productsExpiredSoon" hide-default-footer disable-pagination class="elevation-1">
                
                <template v-slot:[`item.expirationDate`]="{ item }">
                  {{ formatDate(item.expirationDate) }}
                </template>
                <template v-slot:[`item.actionDon`]="{ item }">
                  <v-text-field rounded class="selectQuantity rounded-pill" type="number" v-model="item.quantityDon" placeholder="quantité"></v-text-field>
                </template>
                <template v-slot:[`item.actionPublish`]="{ item }">
                  <v-text-field rounded class="selectQuantity rounded-pill" type="number" v-model="item.quantityPublish" placeholder="quantité"></v-text-field>
                </template>
                <template v-slot:[`item.actionDelete`]="{ item }">
                  <v-text-field rounded class="selectQuantity rounded-pill" type="number" v-model="item.quantityDelete" placeholder="quantité"></v-text-field>
                </template>
              </v-data-table>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="mr-8 mb-4 px-7 rounded-pill appliquer" text @click="manageDonationDialog">Confirmer</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Attention, le produit va être supprimé !</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#ff0000" text @click="closeDelete">Annuler</v-btn>
              <v-btn color="#0C5C53" text @click="deleteObj">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>

          </v-card>
        </v-dialog>
        
          <div class="tabs-sales">
            
            <v-dialog v-model="dialog" max-width="70%">

              <template v-slot:activator="{ on, attrs }" >
                <div class="rounded-pill ajouter-invendu" >
                  <v-text-field
                    class="enterEAN v-text-field--outlined"
                    v-model="product.ean"
                    label="Ajouter un invendu"
                    type="text"
                    hide-details="true"
                    @keypress="isNumber($event)"
                    placeholder="0000000000000"
                  ></v-text-field>
                  <v-btn class="btnAdd"
                  @click="testEan"
                  v-bind="attrs"
                  v-on="on">
                  <i class="mdi mdi-plus"></i></v-btn>
                </div>
              </template>

              <v-card  id="dialogInvendu">
                <v-form v-model="valid" ref="form" lazy-validation width="70%">
                <div class="top-card">

                    <div class="left-card">

                        <div class="card-title">Fiche invendu</div>

                        <div class="detail-product">

                            <div class="codeEan">

                                <v-text-field
                                v-model="product.ean"
                                class="v-text-field--outlined"
                                label="Code EAN"
                                type="text"
                                @keypress="isNumber($event)"
                                placeholder="0000000000000"
                                :rules="eanRules"
                                :disabled="shouldDisable(product)"
                                ></v-text-field>
                    

                                <v-btn 
                                class="validerEan"
                                @click="testEan" 
                                v-if="product.productState != 'online'">
                                Valider 
                                </v-btn>

                            </div>

                    <v-text-field
                        rounded
                        class="rounded-pill champs mr-8"
                        v-model="product.name"
                        label="Nom du produit"
                        counter
                        maxlength="41"
                        placeholder="Nom du produit"
                        type="text"
                        :rules="nameRules"
                        :disabled="shouldDisable(product)"
                    ></v-text-field>

                    <v-text-field
                        rounded
                        class="rounded-pill champs"
                        v-model.number="product.quantity"
                        label="Quantité"
                        type="number"
                        @keypress="isNumber($event)"
                        placeholder="0"
                        :rules="quantityRules"
                        :disabled="product.productState ==='expiredSoon'"
                    ></v-text-field>
                    
                    <v-text-field
                        rounded
                        class="rounded-pill champs mr-8"
                        v-model="product.brandName"
                        label="Nom de la marque"
                        type="text"
                        placeholder="Nom de la marque"
                        counter
                        maxlength="28"
                        :rules="brandNameRules"
                        :disabled="shouldDisable(product)"
                    ></v-text-field>

                        <v-text-field
                        rounded
                        class="rounded-pill champs"
                        v-model.number="product.oldPrice"
                        label=" PU TTC"
                        type="text"
                        @keypress="isDecimal($event)"
                        placeholder="9.99"
                        :rules="oldPriceRules"
                        :disabled="shouldDisable(product)"
                        ></v-text-field>

                        <v-select
                        rounded
                        class="rounded-pill champs mr-8"
                        v-model="product.categoryProduct"
                        :items="categoryItems"
                        label="Catégorie"
                        :rules="neededRule"
                        :disabled="shouldDisable(product)"
                        ></v-select>
                    
                          <v-select
                          rounded
                          class="rounded-pill champs"
                          v-model="product.pourcentageReduction"
                          :items="pourcentageItems"
                          label="% de réduction"
                          :rules="pourcentageReductionRules"
                          :disabled="product.productState ==='expiredSoon'"
                          ></v-select>
                

                          <div>              
                            <img
                            @click="showPopup"
                            class="help"
                            src="../../assets/help.png"
                            />
                          </div>

                        <v-text-field
                        rounded
                        class="rounded-pill champs mr-8"
                        v-model="product.packageId"
                        label="Numéro de lot"
                        type="text"
                        placeholder="Numéro de lot"
                        :rules="neededRule"
                        :disabled="shouldDisable(product)"
                        ></v-text-field>

                        <v-text-field
                        rounded
                        class="rounded-pill champs"
                        :value="getNewPrice"
                        label="PU rem. TTC"
                        type="text"
                        :disabled="true"
                        ></v-text-field>

                    </div>

                    <v-checkbox
                    v-model="product.damaged"
                    class="mt-8"
                    label="Packaging abimé"
                    type="checkbox"
                    :disabled="product.productState ==='expiredSoon'"
                    ></v-checkbox>


                    <div class="date">
                        
                        <v-checkbox
                        v-model="product.ddm"
                        label="Date de préférence (DDM)"
                        type="checkbox"
                        :disabled="shouldDisable(product)"
                        ></v-checkbox>

                      <div class="mr-12">
                        <img
                        class="help"
                        @click="showPopupDDM"
                        src="../../assets/help.png"
                        />
                      </div>

                        <div class="mt-5"
                        v-show="product.ddm"
                        :rules="expirationDateRules"
                        >
                        <datepicker
                            v-model="product.ddmSaisi"
                            :language="fr"
                            ref="programaticOpenDDM" 
                            placeholder="Selectionner une date" 
                            :minimumView="'month'" 
                            :maximumView="'month'"
                            >
                            <div slot="beforeCalendarHeader" class="calender-header">
                            Date de préférence
                            </div>
                            <v-icon slot="afterDateInput"
                            small
                            @click="() => openPickerDDM()"
                            >
                            mdi-calendar
                            </v-icon>
                        </datepicker>
                        </div>

                    </div>

                    <div class="date">

                        <v-checkbox
                        v-model="product.expiration"
                        label="Date d'expiration"
                        type="checkbox"
                        :disabled="shouldDisable(product)"
                        ></v-checkbox>

                      <div class="mr-12">
                        <img
                        class="help"
                        @click="showPopupEXP"
                        src="../../assets/help.png"
                        />
                      </div>

                        <div class="mt-5"
                        v-show="product.expiration"
                        :rules="expirationDateRules"
                        >
                        <datepicker
                            v-model="product.expirationSaisi"
                            :language="fr"
                            ref="programaticOpen" 
                            placeholder="Selectionner une date" 
                            :minimumView="'month'" 
                            :maximumView="'month'"
                            :disabled="shouldDisable(product) || !product.expiration"
                            >
                            <div slot="beforeCalendarHeader" class="calender-header">
                            Date d'expiration
                            </div>
                            <v-icon slot="afterDateInput"
                            small
                            @click="() => openPicker()"
                            >
                            mdi-calendar
                            </v-icon>
                        </datepicker>
                        </div>

                    </div>
    
                </div>
 
                <div class="right-card">

                    <v-btn class="closeDialog mdi mdi-close" @click="close">
                        <span class="mdi mdi-close"></span>
                    </v-btn>

                    <div class="card-img">
                        <v-img
                        @click="openLink(product.imgUrl)"
                        :src="
                            product.imgUrl ? product.imgUrl : 'https://www.idfmoteurs.com/images/pas-image-disponible.png'
                        "
                        class="rounded-circle borderImg"
                        :alt="product.name"
                        width="150"
                        height="150"
                        ></v-img>

                    </div>

                    <v-file-input
                        rounded
                        class="addImg mb-6"
                        type="file"
                        label="Photo du produit"
                        show-size
                        truncate-length="15"
                        v-model="product.img"
                        accept="image/png"
                        :disabled="shouldDisable(product)"
                    ></v-file-input>
                    
                    <v-select
                        rounded
                        class="rounded-pill"
                        v-model="product.shape"
                        placeholder="Forme"
                        :items="shapeItems"
                        :rules="neededRule"
                        :disabled="shouldDisable(product)"
                    ></v-select>
                    
                    
                    
                    <v-text-field
                        rounded
                        class="rounded-pill champs"
                        v-model="product.capacity"
                        label="Contenance"
                        type="text"
                        @keypress="isDecimal($event)"
                        placeholder="50"
                        :rules="capacityRules"
                        :disabled="shouldDisable(product)"
                        ></v-text-field>
                                    
                    
                    <v-select
                    rounded
                        class="rounded-pill"
                        v-model="product.contenanceType"
                        placeholder="Type"
                        :items="contenanceSelectItems"
                        :rules="neededRule"
                        :disabled="shouldDisable(product)"
                    ></v-select>
                    

                    
                    <v-select
                    rounded
                    class="rounded-pill"
                    v-model="product.contenantType"
                    placeholder="Contenant"
                    :items="contenantItems"
                    :rules="neededRule"
                    :disabled="shouldDisable(product)"
                    ></v-select>
                    
                    
                    
                    <v-select
                    rounded
                        class="rounded-pill"
                        v-model="product.presentation"
                        placeholder="Présentation"
                        :items="presentationItems"
                        :rules="neededRule"
                        :disabled="shouldDisable(product)"
                    ></v-select>
                    
                    
                    </div>

                </div>

                <div class="bottom-card">

                <v-textarea
                    outlined
                    :disabled="shouldDisable(product)"
                    name="description"
                    label="Description"
                    v-model="product.description"
                    auto-grow
                    row-height="1"
                ></v-textarea>

                <v-textarea
                    outlined
                    :disabled="shouldDisable(product)"
                    name="wayToUse"
                    label="Conseils d'utilisation"
                    v-model="product.wayToUse"
                    auto-grow
                    row-height="1"
                ></v-textarea>

                <v-textarea
                    :disabled="shouldDisable(product)"
                    v-if="product.categoryProduct == 'Santé'"
                    outlined
                    name="precautions"
                    label="Précautions d’emploi"
                    v-model="product.precautions"
                    auto-grow
                    row-height="1"
                ></v-textarea>

                <v-textarea
                    outlined
                    :disabled="shouldDisable(product)"
                    name="ingredients"
                    label="Liste des ingrédients"
                    v-model="product.ingredients"
                    auto-grow
                    row-height="1"
                ></v-textarea>

                <v-col cols="12"
                    v-if="product.productState != 'expiredSoon'">
                    <v-checkbox
                    v-model="noMedic"
                    label="J'atteste que le produit n'est ni un médicament remboursable, ni un dispositif médical de classe IIb ou III remboursable."
                    type="checkbox"
                    :rules="neededRule"
                    ></v-checkbox>
                </v-col>

                <v-row>
                    <v-col cols="8">
                    <v-textarea
                        outlined
                        name="remarks"
                        label="Remarques (facultatif)"
                        v-model="product.remarks"
                        auto-grow
                        row-height="1"
                        :disabled="shouldDisable(product)"
                    ></v-textarea>
                    
                    </v-col>
                    <div>
                      <img
                          class="help rem"
                          src="../../assets/help.png"
                          @mouseover="remarquesHover = true"
                          @mouseleave="remarquesHover = false"
                          />
                    </div>

                        <div class="remarques-hover" v-show="remarquesHover">
                        Informations utilisées par notre équipe afin de bien identifier les différentes caractéristiques du produit.
                        </div>
                </v-row>

                </div>
                </v-form>
                        
                <v-card-actions class="ms-14 me-14 pb-8 pt-8">
                
                    <v-btn
                    text
                    v-if="
                        checkAdminOrTest() &&
                        (
                        product.productState == 'expiredSoon'
                        )
                    "
                    @click="sendOnline">
                        Vendre
                    </v-btn>

                    <v-btn
                    text
                    @click="openDialogDonner"
                    v-if="isAvailableToDonate(product)">
                        Donner
                    </v-btn>
                    
                    <v-col cols="2">
                        <v-text-field
                            v-if="checkAdminOrTest()  && 
                            product.productState == 'expiredSoon'"
                            v-model="product.donationDate"
                            label="Date de retrait par l'association"
                            type="date"
                            :rules="donationDateRules"
                        >
                        </v-text-field>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-btn
                    text
                    @click="save"
                    v-if="
                        (editedIndex == -1 ||
                        (editedIndex != -1 && checkOwner(product))) &&
                        (product.productState == 'online') 
                        || (checkAdminOrTest() && product.productState)
                    "
                    >
                        Modifier
                    </v-btn>

                    <v-btn
                    class="vendre rounded-pill"
                    text
                    @click="save"
                    v-if="
                        (editedIndex == -1 ||
                        (editedIndex != -1 && checkOwner(product))) &&
                        (product.productState != 'online' &&
                        product.productState != 'expiredSoon')
                    "
                    >
                        Vendre
                    </v-btn>

                    <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="green"
                    ></v-progress-circular>

                </v-card-actions>

                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Attention, le produit va être supprimé !</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="#ff0000" text @click="closeDelete">Annuler</v-btn>
                      <v-btn color="#0C5C53" text @click="deleteObj">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>

                  </v-card>
                </v-dialog>

                <v-dialog
                  v-model="dialogPourcentage"
                  min-width="500px"
                  min-height="500px"
                >
                  <v-card
                    ><v-card-text
                      ><v-row style="padding-top: 20px; align-items: center"
                        ><v-col
                          cols="6"
                          style="border-right: 1px solid #e0e0e0;"
                        >
                          <br />La loi Européenne de modernisation de l’économie de
                          2008, indique que toute société doit lutter contre la
                          péremption de ses stocks.<br /><br />
                          Elle autorise le déstockage, jusqu’à présent qualifié de
                          soldes déguisés, tout au long de l’année et permet dans ce
                          cadre
                          <b>la revente à perte</b> des produits suivants :<br /><br />
                          <ul>
                            <li>
                              Les produits périssables dont
                              <b>la date limite de vente arrive à échéance</b> peuvent
                              être revendus à perte (exemple : date courte où il reste 3
                              mois avant l’expiration).<br /><br />
                            </li>
                            <li>
                              C’est le cas pour les produits qui se vendent surtout
                              <b>pendant une seule saison</b>, qui peuvent être vendus à
                              perte lorsque celle-ci se termine (exemple : sur-stock de
                              type pulvérisateur anti-moustique en hiver).
                            </li>
                          </ul>
                          <br />
                          <i
                            >Réf. : Loi n° 2008-776 du 4 août 2008, de modernisation de
                            l'économie</i
                          ></v-col
                        >
                        <v-divider vertical></v-divider>
                        <v-col cols="6"
                          ><img
                            src="../../assets/stock_comments.png"
                            style="max-width: 100%"
                        /></v-col> </v-row></v-card-text></v-card
                ></v-dialog>

                <v-dialog v-model="dialogDDM" width="500">
                  <img src="../../assets/dateDDM.png" />
                </v-dialog>

                <v-dialog v-model="dialogExpiration" width="500">
                  <img src="../../assets/dateEXP.png" />
                </v-dialog>

                
              </v-card>
              
              
            </v-dialog>
            
            <v-dialog v-model="dialogSante" width="400">
              <v-card class="dialogSante" >
                <div>Le produit selectionné correspond à un produit de santé soumis aux règles sur la publicité du CSP</div>
                <div>En confirmant, vous acceptez les risques</div>
                <div class="actionSante">
                  <v-btn @click="dialogSante = false">Annuler</v-btn>
                  <v-btn @click="save" class="rounded-pill">Confirmer</v-btn>
                </div>
              </v-card>
            </v-dialog>
                              
            <v-dialog v-model="dialogDonner" width="400">
              <v-card class="dialogSante" >
                <div>Quelle est la quantité à donner ?</div>
                <v-text-field
                  rounded
                  class="rounded-pill quantityDon"
                  v-model="quantityDon"
                  label="Quantité"
                  type="number"
                  @keypress="isNumber($event)"
                  placeholder="0"
                  :min="0"
                  :max="product.quantity"
                  :rules="quantityRules"
                  :disabled="product.productState ==='expiredSoon'"
                ></v-text-field>
                <div>En confirmant, votre invendu pourra faire l'objet d'un don pour une association.</div>
                <div class="actionSante">
                  <v-btn @click="dialogDonner = false">Annuler</v-btn>
                  <v-btn @click="addDonation(parseInt(quantityDon))" class="rounded-pill">Confirmer</v-btn>
                </div>
              </v-card>
            </v-dialog>
          </div>
      </div>

      <div class="data">

          <div class="tableau">
              <div class="tableau-title">
                  <div class="name">Invendu</div>
                  <div class="marque">Marque</div>
                  <div>Stock</div>
                  <div>Date d'exp.</div>
                  <div>PU rem. TTC</div>
                  <div>Action</div>
              </div>
              <div>
                  <div class="invendus" v-for="(product, index) in paginatedProducts" :key="index">

                    <div class="basic-infos">
                        <div class="seeMore">
                            <span :class="['mdi', {'mdi-chevron-right': seeMoreIndex != index}, {'mdi-chevron-down': seeMoreIndex === index}, 'open']"
                            @click="showInfos(index)"></span>
                        </div>                                
                            <div class="name">
                                {{ product.ean }} <br>
                                {{ product.name }}
                            </div>
                            <div class="marque">
                                {{ product.brandName }}
                            </div>
                            <div>
                                {{ product.quantity }}
                            </div>
                            <div>
                                {{ product.expirationDate }}
                            </div>
                            <div>
                                {{ formatPrice(product.price) }} €
                            </div>
                            <div>
                            <v-icon
                              small
                              class="mr-2"
                              @click="editItem(product)"
                              v-if="
                                (checkOwner(product) || checkAdminOrTest)
                              "                    
                            >
                              mdi-file-document-edit-outline
                            </v-icon>
                            <v-icon
                              small
                              class="mr-2"
                              @click="editItem(product)"
                              v-if="
                                (checkOwner(product) || checkAdminOrTest) &&
                              product.productState == 'unverified'
                              "
                            >
                              mdi-file-document-edit-outline
                            </v-icon>
                            <v-icon
                              small
                              @click="deleteItem(product)"
                              v-if="checkOwner(product) || checkAdminOrTest"
                            >
                              mdi-trash-can-outline
                            </v-icon>
                            </div>
                      </div>


                      <div>
                          <table class="supp-infos" v-show="seeMoreIndex === index">
                              <thead>
                                  <tr>
                                      <th>Catégorie</th>
                                      <th>N° lot</th>
                                      <th class="packaging">Packaging abimé</th>
                                      <th>Remise</th>
                                      <th v-show="checkAdmin()">E-mail vendeur</th>
                                      <th v-show="checkAdmin()">Ville</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td>{{ product.categoryProduct }}</td>
                                      <td>{{ product.packageId }}</td>
                                      <td class="packaging-td"><input type="checkbox" v-model="product.damaged" disabled></td>
                                      <td>{{ product.pourcentageReduction }}%</td>
                                      <td v-show="checkAdmin()">{{ product.mail }}</td>
                                      <td v-show="checkAdmin()">{{ product.city }}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      
                    </div>
                  </div>
                </div>
              
                </div>
                
                <div class="nav-pages">
                  <div>{{ products.length }} invendus</div>
                  <div id="pagination-container">
                      <button class="mdi mdi-chevron-double-left" @click="currentPage = 1" :disabled="currentPage === 1"></button>
                      <button class="mdi mdi-chevron-left" @click="prevPage" :disabled="currentPage === 1"></button>
                      <button class="mdi mdi-chevron-right" @click="nextPage" :disabled="currentPage === totalPages"></button>
                      <button class="mdi mdi-chevron-double-right" @click="currentPage = totalPages" :disabled="currentPage === totalPages"></button>
                  </div>
                  <div class="select-nb">
                    <span>Produits par page</span>
                    <button class="nb-products" @click="itemsPerPage = 5">5</button>
                    <button class="nb-products" @click="itemsPerPage = 10">10</button>
                    <button class="nb-products" @click="itemsPerPage = 15">15</button>
                    <button class="nb-products" @click="itemsPerPage = products.length">Tous</button>
                  </div>
                </div>

    </div>

</div>

  <footer class="footer-style">
          v2.4.1
        </footer>
</div>
</div>
</template>


<script>
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import {fr} from 'vuejs-datepicker/dist/locale';
import { ordersCollection } from "../../main";
import moment from "moment";
import {
getDateColor,
isNumber,
getDataFromEan,
checkOwner,
isDecimal,
donateProduct,
sendOnline,
sendObject,
deleteObj,
getProductsList,
updateObject,
manageDonation,
} from "./../arrayData/functionArrayData";
import "firebase/compat/database";
import "animate.css";

export default {
  components: { Datepicker },

  data: () => ({
  statusFilter: false,
  statusHover: false,
  remarquesHover: false,
  filterStatus: "all",
  seeMoreIndex: 0,
  quantityDon: 0,
  currentPage: 1,
  itemsPerPage: 5,
  tab: null,
  tabs: [
      { title: 'Liste'},
      { title: 'En cours'},
      { title: 'Historique'}
  ],
  fr: fr,
  disabled: 0,
  loading: false,
  search: "",
  noMedic: false,
  checkTraceability: false,
  valid: false,
  uidAdmin: process.env.VUE_APP_UID_ADMIN,
  uidTest: process.env.VUE_APP_UID_TEST,
  uidDemo: process.env.VUE_APP_UID_DEMO,
  statusItems: ["Tous", "Publié", "Non publié"],
  id: "",
  snackbar: false,
  isUploading: false,
  message: null,
  dialog: false,
  dialogDonation: false,
  dialogDelete: false,
  products: [],
  productsFiltered: [],
  orders: [],
  orderSommary: [],
  order: null,
  dialogSante: false,
  dialogDonner: false,
  dialogDDM: false,
  dialogExpiration: false,
  dialogPourcentage: false,
  productsExpiredSoon: [],
  editedIndex: -1,
  product: {},
  contenanceSelectItems: [
  "g",
  "mL",
  "L",
  "ampoules",
  "capsules",
  "comprimés à avaler",
  "comprimés à croquer",
  "comprimés effervescents",
  "comprimés orodispersibles",
  "gélules",
  "gommes",
  "pastilles",
  "sachets",
  "tampons",
  "unidoses",
  "unité(s)",
],
contenantItems: [
  "Aluminium",
  "Carton",
  "Céramique",
  "Papier",
  "Plastique non recyclé",
  "Plastique recyclé",
  "Tissu",
  "Verre",
  "N/A",
],
pourcentageItems: [30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80],
categoryItems: [
  "Aromathérapie",
  "Bébé",
  "Cheveux",
  "Compléments alimentaires",
  "Cosmétiques",
  "Homme",
  "Hygiène",
  "Maquillage et accessoires",
  "Minceur",
  "Phytothérapie",
  "Santé",
  "Sexualité",
  "Sport",
  "Vétérinaire",
],
shapeItems: [
  "Aérosol",
  "Baume",
  "Biphase",
  "Crème",
  "Fluide",
  "Gel",
  "Gouttes",
  "Huile",
  "Lait",
  "Liquide",
  "Lotion",
  "Masque",
  "Mousse",
  "Pain",
  "Poudre",
  "Pâte",
  "Sirop",
  "Solide",
  "Solution micellaire",
  "Sérum",
  "Vernis",
],
presentationItems: [
  "Boîte",
  "Brosse",
  "Coffret",
  "Flacon",
  "Flacon pompe",
  "Kit",
  "Lot",
  "Lingette",
  "Patch",
  "Pipette",
  "Plaquette",
  "Pot",
  "Recharge",
  "Roll-on",
  "Sachet",
  "Spray",
  "Stick",
  "Stylo",
  "Tube",
  "Tube pompe",
],
nameRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) =>
    (v && v.length <= 41) ||
    "Le nom du produit doit comporter moins de 42 caractères",
],
eanRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) =>
    (v && v.length <= 13) ||
    "Le code EAN doit comporter moins de 14 caractères",
  (v) => /^\d+$/.test(v) || "seuls des chiffres entiers sont autorisés",
],
quantityRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) => /^\d+$/.test(v) || "seuls des chiffres entiers sont autorisés",
],
brandNameRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) =>
    (v && v.length <= 28) ||
    "Le nom de la marque doit comporter moins de 29 caractères",
],
pourcentageReductionRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) =>
    (v >= 30 && v <= 80) ||
    "Le pourcentage de réduction doit être compris entre 30 et 80",
  // v => /^\d+$/.test(v)||'seuls des chiffres entiers sont autorisés',
],
neededRule: [(v) => !!v || "Ce champ est obligatoire"],
oldPriceRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) =>
    /^\d*\.?\d+$/.test(v) || "seuls des nombres (avec '.') sont autorisés",
],
capacityRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) =>
    /^\d*\.?,?\d+$/.test(v) || "seuls des chiffres entiers sont autorisés",
],
expirationDateRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) =>
    (v && v.length <= 10) ||
    "La date d'expiration doit comporter moins de 8 caractères",
],
donationDateRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) =>
    (v && v.length <= 10) ||
    "La date de donnation doit comporter moins de 8 caractères",
],
}),

watch: {
  dialog(val) {
    val || this.close();
  },
  dialogDelete(val) {
    val || this.closeDelete();
  }
},

computed: {
  totalPages() {
      return Math.ceil(this.products.length / this.itemsPerPage);
  },
  paginatedProducts() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      const term = this.search.toLowerCase();
      if (this.filterStatus == 'all' || this.filterStatus == 'Tous')
      {
        if (term == '')
          return this.products.slice(startIndex, endIndex);
        else
          return this.products.filter(product => {
            return product.name.toLowerCase().includes(term)
            || product.brandName.toLowerCase().includes(term)
            || product.categoryProduct.toLowerCase().includes(term)
        }).slice(startIndex, endIndex)
      }
      else {
        if (this.filterStatus == "Publié")
          return this.products.filter(product => product.productState == "online").slice(startIndex, endIndex);
        else
          return this.products.filter(product => product.productState != "online").slice(startIndex, endIndex);
      }
  },
  headersDonation() {
    let headersDonation = [
      {
        text: "Invendu",
        align: "center",
        value: "name",
      },
      { text: "Marque", value: "brandName", align: "center" },
      {
        text: "Date d'exp",
        value: "expirationDate",
        dataType: "Date",
        align: "center"
      },
      { text: "Stock", value: "quantity", align: "center" },
      { text: "Stock don", value: "actionDon", sortable: false, align: "center" },
      { text: "Stock publié", value: "actionPublish", sortable: false, align: "center" },
      { text: "Stock supprimé", value: "actionDelete", sortable: false, align: "center" },
    ];
    return headersDonation;
  },
  getNewPrice: function () {
    if (
      this.product["pourcentageReduction"] && this.product["pourcentageReduction"] != null &&
      this.product["oldPrice"] && this.product["oldPrice"] != null
    ) {
      return Number(
        (
          (this.product.oldPrice *
            (100 - this.product["pourcentageReduction"])) /
          100
        ).toFixed(2)
      );
    } else {
      return 0;
    }
  },
},

mounted() {
  this.getProductsList();
  this.getOrdersInProgress();
  ordersCollection
    .where("status", "==", "completed")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach(async (doc) => {
        let data = doc.data();
        let checkAccess =
          data.products[0].supplier == localStorage.getItem("uid") ||
          this.checkAdmin();

        if (checkAccess) {
          let order = {
            docId: doc.id,
            status: data.status,
            orderID: data.orderID,

            name:
                data.user.userName ??
                `${data.user.firstname} ${data.user.lastname}`,
            userEmail:
              data.user.userEmail,
            userAddress: data.user.userAddress,
            userPostalCode: data.user.userPostalCode,
            userCity: data.user.userCity,
            userPhone: data.user.userPhone,

            products: data.products,
            totalPrice: data.totalPrice ?? data.products
              .reduce((acc, product) => {
                return acc + product.price;
              }, 0)
              .toFixed(2),
            
            pharmacyName: data.pharmacyName,
            pharmacyAddress: data.pharmacyAddress,
            pharmacyPostalCode: data.pharmacyPostalCode,
            pharmacyCity: data.pharmacyCity,
            pharmacyEmail: data.pharmacyEmail,
            pharmacyPhone: data.pharmacyPhone,

            retrieveDate: data.timestamp,
          };

          this.orderSommary.push(order);
        }
      });
    });
},

methods: {
  formatPrice(price) {
    const floatPrice = parseFloat(price);
    if (isNaN(floatPrice)) {
        return "Prix invalide";
    }
    return floatPrice.toFixed(2).replace('.', ',');
  },
  prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
  nextPage() {
      if (this.currentPage < this.totalPages) {
          this.currentPage++;
      }
  },
  async getOrdersInProgress() {
    ordersCollection
      .where("status", "!=", "completed")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          let data = doc.data();
          let checkAccess =
            data.supplier == localStorage.getItem("uid") ||
            this.checkAdmin();
          if (
            checkAccess &&
            (data.status == "validated" || data.status == "prepared")
          ) {
            console.log("access ok")
            let order = {
              docId: doc.id,
              status: data.status,
              orderID: data.orderID,

              name:
                data.user.userName ??
                `${data.user.firstname} ${data.user.lastname}`,
              userEmail:
                data.user.userEmail,
              userAddress: data.user.userAddress,
              userPostalCode: data.user.userPostalCode,
              userCity: data.user.userCity,
              userPhone: data.user.userPhone,

              products: data.products,
              totalPrice: data.totalPrice ?? data.products
                .reduce((acc, product) => {
                  return acc + product.price;
                }, 0)
                .toFixed(2),
              
              pharmacyName: data.pharmacyName,
              pharmacyAddress: data.pharmacyAddress,
              pharmacyPostalCode: data.pharmacyPostalCode,
              pharmacyCity: data.pharmacyCity,
              pharmacyEmail: data.pharmacyEmail,
              pharmacyPhone: data.pharmacyPhone,

              date: data.created_at
                ? moment
                    .unix(data.created_at["seconds"])
                    .format("YYYY/MM/DD HH:mm:ss")
                : data.date,
            };

            this.orders.push(order);
          }
        });
      });
  },
  openPicker() {
    this.$refs.programaticOpen.showCalendar();
  },
  openPickerDDM() {
    this.$refs.programaticOpenDDM.showCalendar();
  },
  showInfos(index) {
      if (this.seeMoreIndex === index)
          this.seeMoreIndex = -1;
      else
          this.seeMoreIndex = index;
  },
  formatDate(value) {
      return moment(value).format("DD/MM/YYYY")
  },
  getDateColor: getDateColor,
  isNumber: isNumber,
  checkOwner: checkOwner,
  isDecimal: isDecimal,
  donateProduct: donateProduct,
  manageDonation: manageDonation,
  sendOnline: sendOnline,
  deleteObj: deleteObj,
  getProductsList: getProductsList,
  updateObject: updateObject,
  sendObject: sendObject,
  async testEan() {
    let product = await getDataFromEan(this.product["ean"]);
    if (product != -1)
      this.product = Object.assign({}, product);
  },
  openLink(url) {
    window.open(url);
  },
  checkAdmin() {
    return (this.uidAdmin === localStorage.getItem("uid"));
  },
  checkAdminOrTest() {
    let valResult =
      localStorage.getItem("uid") === this.uidAdmin || localStorage.getItem("uid") === this.uidTest
    return valResult;
  },
  isAvailableToDonate(product) {
    return (product.productState === 'online');
  },
  shouldDisable(product) {
    return (product.productState === 'online' || product.productState === 'expiredSoon') && 
    (localStorage.getItem("uid") !== this.uidAdmin && localStorage.getItem("uid") !== this.uidTest);
  },
  editItem(item) {
    this.editedIndex = this.products.indexOf(item);
    this.product = Object.assign({}, item);
    this.dialog = true;
  },
  deleteItem(item) {
    this.editedIndex = this.products.indexOf(item);
    this.product = Object.assign({}, item);
    this.dialogDelete = true;
  },
  closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.product = {};
      this.editedIndex = -1;
    });
  },
  manageDonationDialog() {
    this.manageDonation(this.products);
    this.dialogDonation = false;
  },
  addDonation() {
    this.donateProduct(this.editedIndex, this.quantityDon)
    .then(() => {
      this.dialogDonner = false;
      this.dialog = false;
    });
  },
  close() {
    this.dialog = false;
    this.isUploading = false;
    this.$nextTick(() => {
      this.product = {};
      this.editedIndex = -1;
    });
  },
  vendreAction(product) {
    this.product = product;
    if (this.valid) {
      if (this.product.categoryProduct === "Santé")
        this.dialogSante = true;
      else
      this.save();
    }
  },
  notValidForm() {
    this.message = "remplissage du formulaire incorrect, demande non envoyée";
    this.snackbar = true;
  },
  save() {
    if (this.valid) {
      if (this.editedIndex > -1) {
        this.loading = true;
        this.updateObject();
      } else if (this.editedIndex === -1) {
          this.loading = true;
          this.sendObject();
      }
    } else {
      this.message =
        "remplissage du formulaire incorrect, demande non envoyée";
      this.snackbar = true;
    }
  },
  showPopup() {
    this.dialogDDM = false;
    this.dialogExpiration = false;
    this.dialogPourcentage = true;
  },
  showPopupDDM() {
    this.dialogPourcentage = false;
    this.dialogExpiration = false;
    this.dialogDDM = true;
  },
  showPopupEXP() {
    this.dialogDDM = false;
    this.dialogPourcentage = false;
    this.dialogExpiration = true;
  },
  getFile() {
    if(this.uidAdmin === localStorage.getItem("uid")) {
      let fileUpload = document.getElementById('fileUpload')
      if (fileUpload != null) {
        fileUpload.click()
      }
    }
  },
  openDialogDonner() {
    this.dialogDonner = true;
  },
},
}
</script>

<style>

.listeDons {
  margin: 4vh 2vw 0 2vw;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 0.5px solid rgba(128, 128, 128, 0.265);
  border-radius: 20px 20px 0 0;
  height: 88vh;
}

.top-content {
  padding: 1% 2%;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.tabs {
  display: flex;
  width: 100%;
}

.tabs-sales {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.tab {
  padding: 0.5% 3%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
  margin-right: 2vw;
  color: #015c53;
  font-size: 20px;
  font-weight: bold;
}

.status {
  display: flex;
  align-items: center;
  position: relative;
  border: 2px dashed grey;
  color: grey;
  padding: 0.5% 3%;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
  margin-left: 15vw;
}

.ajouter-invendu {
  padding: 1% 3%;
  height: 5vh !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
  margin-left: 1vw;
  margin-right: 6vw;
  color: #015c53;
  background-color: #FDD82A;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center !important;
}

.ajouter-invendu .v-input {
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0 !important;
  font-size: 13px !important;
}

.v-input__slot {
  margin: 0 !important;
}

.enterEAN {
  padding: 0;
}

.v-text-field__details {
  display: none;
}

.ajouter-invendu .theme--light.v-label {
  color: #015c53 !important;
}

.active {
  background-color: #B5E5DC;
}

.closeDialog {
  padding: 0 !important;
  font-size: 25px;
  min-width: 1em !important;
  width: 2em !important;
  height: 2em !important;
  border-radius: 50%;
  background-color: white !important;
  margin-left: 95%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
  cursor: pointer;
}

.data {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80% !important;
}

.validerEan {
  color:#015c53 !important;
  background-color: #FDD82A !important;
  border-radius: 20px !important;
}

.tableau {
  margin-top: 1.5vh;
  width: 95%;
  height: 95% !important;
  overflow: auto;
  border: 2px solid rgba(149, 149, 149, 0.501);
  border-radius: 20px;
}

.tableau * {
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
}

.tableau th {
  font-size: 20px;
}

.tableau-title {
  padding: 1% 4%;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  display: flex;
  border: 1px solid rgba(149, 149, 149, 0.501);
  border-radius: 17px;
  width: 100%;
  height: 6vh;
}

.tableau-title div {
  font-weight: bold;
  color: grey;
  font-size: 20px;
}

.invendus {
  padding: 1% 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 18px;
}

.invendus {
  border-bottom: 1px solid lightgrey;
}

.invendus:last-of-type {
border: none;
}

.historique {
  padding: 1% 4% !important;
  display: flex;
  width: 100%;
}

.historique div {
  display: flex;
  width:  8vw;
}

.basic-infos {
  display: flex;
}

.more-infos {
  margin-left: 2%;
  margin-right: 1%;
}

.basic-infos div, .tableau-title div {
  width: 8vw;
}

.name {
  width: 20vw !important;
}

.marque {
  width: 20vw !important;
}

.seeMore {
  width: 4% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.open {
  padding: 2%;
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
}

.supp-infos {
  border-radius: 20px;
  border: 2px solid rgba(128, 128, 128, 0.741);
    margin-left: 5%;
    margin-top: 1vh;
    background-color: white;
    padding: 1%;
    text-align: left;
    width: fit-content;
}

.supp-infos th, .supp-infos td{
width: 8vw;
height: 3vh;
padding-right: 2vw;
}

.supp-infos .packaging {
  width: 12vw;
}

.packaging-td {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3vh;
}

.v-text-field--placeholder {
  font-weight: bold;
  color: rgb(93, 93, 93);
}

.bis {
width: 12vw !important;
}

.historique {
display: flex;
border-bottom: 1px solid lightgrey;
padding: 1% 0;
  width: 100%;
  font-size: 18px;
}

.nav-pages {
  display: flex;
  width: 100%;
  padding: 2vh 3vw;
  justify-content: space-between;
}

.nav-pages * {
  color: rgb(146, 146, 146);
  font-weight: bold;
  font-size: 18px;
}

.nb-products {
  border: 1px solid grey;
}

.select-nb {
  width: 26%;
}

.textfield .v-input__slot {
  border-radius: 100px;
  background-color: white;
  border: solid 1px #d3d3d3;
  height: 50px;
}

.textfield .v-label--active {
    background-color: white;
}

.select-nb button {
  margin-left: 0.5vw;
  padding: 1%;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
}

.select-nb button:last-of-type {
  width: 3em;
  border-radius: 40%;
  height: 2em;
}

.btnAdd {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: #015c53 !important;
  font-size: 30px !important;
}

.status-hover {
  position: absolute;
  top: 120%;
  left: 0;
  padding: 4% 6%;
  border-radius: 20px;
  border: 1px solid grey;
  width: 15vw;
  font-size: 15px;
  z-index: 10;
  background-color: white;
}

.status-filter {
  text-align: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  top: 120%;
  left:0;
  padding: 4% 6%;
  width: 15vw;
  z-index: 12;
  font-size: 15px;
  background-color: white;
  border-radius: 20px;
  border: 1px solid grey;
}

.status-filter .v-select, .status-filter button {
  border: 1px solid grey;
  padding: 1%;
  width: 90%;
}

.status-filter .v-select {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
}

.appliquer {
  color: #015c53 !important;
  background-color: #FDD82A !important;
  margin-top: 1vh;
  text-transform: none !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

.codeEan {
  display: flex;
  width: 40%;
  margin-right: 40%;
  margin-left: 5%;
}

.quantityDon {
  border: 1px solid grey;
  width: 80%;
  margin-left: 10%;
  height: 50px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328) ;
}

.vendre {
  background-color: #FDD82A !important;
  color:#015c53 !important;
  padding: 1%  8%!important;
  font-weight: bold;
}

.dialogSante {
  display: flex;
  color: grey;
  font-weight: bold;
  flex-direction: column;
  text-align: center;
  gap: 3vh;
  padding: 6% 8%;
  border-radius: 20px;
  border: 3px solid #015c53
}

.footer-style {
  position: absolute;
  bottom: 0;
  font-size: 10px;
  left: 0;
  width: 100%;
  margin-top: 30px;
  color: black;
}

.v-dialog, .v-card {
  border-radius: 20px !important;
}

.search {
  position: fixed;
  font-size: 12px;
  left: 14vw;
  width: 18vw;
  top: 3.5vh;
  background-color: white;
  padding: 0.3% 1%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
}

.search input {
  padding-left: 3%;
  outline: none;
  width: 90%;
}

.search input::placeholder {
  font-weight: bold;
}

.selectQuantity {
  padding: 1vh 0 !important;
  margin-top: 2vh !important;
  margin-bottom: 2vh !important;
  margin-left: 10% !important;
  border: 1px solid #808080;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
}

.text-h5 {
  color: #3d3c3c;
  font-size: 10px;
}

.invenduPerime {
  border: 3px solid #015c53 !important;
}

.invenduPerime .title {
  font-size: 14px !important;
}

#dialogInvendu {

  .closeDialog {
  padding: 0 !important;
  font-size: 25px;
  min-width: 1em !important;
  width: 2em !important;
  height: 2em !important;
  border-radius: 50%;
  background-color: white !important;
  margin-left: 95%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
  }

  .data {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  }

  .validerEan {
  color:#015c53 !important;
  background-color: #FDD82A !important;
  border-radius: 20px !important;
  text-transform: none;
  padding: 0 2vw !important;
  }

  .top-card {
  display: flex;
  }

  .bottom-card {
  padding: 0 2%;
  display: flex;
  gap: 3vh;
  flex-direction: column;
  }

  .bottom-card .v-textarea .v-input__slot{
    min-height: 10vh !important;
    padding: 1.5%!important;
  }

  .left-card {
    width: 60%;
    padding: 2vh;
  }

  .card-title {
    color: grey;
    font-size: 30px;
    height: 4vh;
    font-weight: bold;
    margin-bottom: 4vh;
  }


  .detail-product {
    display: flex;
    flex-wrap: wrap;
  }

  .detail-product .v-input {
    padding: 0 !important;
    display: flex;
    align-items: center;
  }

  .detail-product .champs {
  height: 50px;
  width: 40%;
  margin-bottom: 3vh;
  color: grey !important;
  border: 1px solid rgb(94, 94, 94);
  padding: 2% 2% 1% 2%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
  }

  .right-card * {
    color: rgba(0,0,0,0.5);
  }
  .closeDialog .v-btn__content {
    font-size: 2em;
    padding: 2% !important;
    color: black !important;
  }
  .v-text-field--placeholder {
    font-weight: bold;
    color: rgb(93, 93, 93);
  }

  .help {
    cursor: pointer;
    width: 1.2em;
    height: 1.2em;
    position: absolute;
  }

  .right-card {
    padding: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 38%;
  }

  .right-card .v-select, .right-card .champs {
  height: 50px;
  border: 1px solid grey;
  padding: 2% 2% 2% 2%;
  width: 80% !important;
  flex: none;
  margin-bottom: 3vh;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
  }

  .right-card .champs {
    padding: 0;
    display: flex;
    align-items: center;
  }


  .card-img {
  margin: 3vh 0;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
  }

  .addImg {
  width: 80%;
  }

  .date {
  display: flex;
  }

  .date .v-input {
  width: 30%;
  }

  .bis {
  width: 12vw !important;
  }

  .nav-pages {
  display: flex;
  width: 100%;
  padding: 2vh 3vw;
  justify-content: space-between;
  }

  .nav-pages * {
  color: rgb(146, 146, 146);
  font-size: 18px;
  }

  .nb-products {
  border: 1px solid grey;
  }

  .select-nb {
  width: 24%;
  }

  .textfield .v-input__slot {
  border-radius: 100px;
  background-color: white;
  border: solid 1px #d3d3d3;
  height: 50px;
  }

  .textfield .v-label--active {
    background-color: white;
  }

  .select-nb button {
  margin-left: 0.5vw;
  padding: 1%;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
  }

  .select-nb button:last-of-type {
  width: 3em;
  border-radius: 40%;
  height: 2em;
  }

  .btnAdd {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: #015c53 !important;
  font-size: 30px !important;
  }

  .remarques {
  position: relative;
  }

  .remarques-hover {
  position: absolute;
  padding: 1%;
  bottom: 5%;
  right: 14%;
  width: 12vw;
  background-color: white;
  border-radius: 20px;
  z-index: 20;
  border: 1px solid grey;
  }

  .status-hover {
  position: absolute;
  top: 120%;
  left: 0;
  padding: 4% 6%;
  border-radius: 20px;
  border: 1px solid grey;
  width: 15vw;
  font-size: 15px;
  z-index: 10;
  background-color: white;
  }

  .status-filter {
  text-align: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  top: 120%;
  left:0;
  padding: 4% 6%;
  width: 15vw;
  z-index: 12;
  font-size: 15px;
  background-color: white;
  border-radius: 20px;
  border: 1px solid grey;
  }

  .status-filter .v-select, .status-filter button {
  border: 1px solid grey;
  padding: 1%;
  width: 90%;
  }

  .status-filter .v-select {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
  }

  .appliquer {
  color: #015c53 !important;
  background-color: #FDD82A !important;
  margin:1vh 1vh 1vh 1vh;
  text-transform: none !important;
  font-weight: bold !important;
  font-size: 16px !important;
  border: none !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  }

  .codeEan {
    display: flex;
    align-items: center;
    width: 40%;
    margin-right: 40%;
    margin-left: 5%;
    margin-bottom: 2vh;
  }

  .codeEan .v-input {
    margin: 0;
  }

  .quantityDon {
  border: 1px solid grey;
  width: 80%;
  margin-left: 10%;
  height: 50px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328) ;
  }

  .vendre {
  background-color: #FDD82A !important;
  color:#015c53 !important;
  padding: 1vh 4vw !important;
  font-weight: bold;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .dialogSante {
  display: flex;
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: bold;
  flex-direction: column;
  text-align: center;
  gap: 3vh;
  padding: 6% 8%;
  border-radius: 20px;
  border: 3px solid #015c53 !important;
  }

  .actionSante {
  width: 80%;
  margin: 0 10%;
  display: flex;
  justify-content: space-between;
  }

  .actionSante .v-btn {
  background-color: transparent !important;
  color: #015c53 !important;
  font-weight: bold !important;
  font-size: 16px !important;
  box-shadow: none;
  text-transform:none;
  }

  .actionSante .v-btn:last-of-type {
  background-color: #FDD82A !important;
  }

  .text-h5 {
    color: #3d3c3c;
    font-size: 10px;
  }

  .v-dialog, .v-card {
  border-radius: 20px !important;
  }

  .v-textarea {
  border-radius: 25px;
  }

  .v-textarea .v-input__slot {
  border: 1px solid rgb(94, 94, 94);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);

  }

  .v-card__actions .v-btn__content {
    text-transform: none;
    font-size: 18px;
    color: rgba(1, 92, 83, 1);
  }

}

@media screen and (max-width: 1350px) {
  .search {
    width: 18vw;
    top: 22px;
  }

  .tableau-title div {
    font-size: 10px;
  }

  .invendus {
    font-size: 10px;
  }

  .nav-pages * {
    font-size: 10px;
  }

  .data {
    height: 75% !important;
  }

  .tableau th {
    font-size: 10px;
  }

  .select-nb {
    width: 30%;
  }

}


</style>