 <template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="text-h5"> Bienvenue sur l'espace pro de KéaBot ! </v-card-title>
      <v-card-text>Sur cet espace vous allez pouvoir référencer vos soins anti-gaspi qui périment bientôt (jusqu’à 1 mois avant la date d’expiration), votre sur-stock ou des produits avec un packaging abîmé.</v-card-text>
      <v-card-text>Pour rappel, cette offre est sans engagement, sans abonnement. Notre rémunération s’effectue via une commission sur chaque vente réalisée.</v-card-text>
      <v-card-text>Pour continuer à lutter contre le gaspillage nous lançons une seconde offre qui à pour objectif d’anticiper vos invendus.
          Concrètement vous allez pouvoir planifier des alertes, lorsque vos produits arrivent bientôt à échéance de la date de péremption.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="validWithoutInterest">
          non
        </v-btn>
        <v-btn color="green darken-1" text @click="validInterest">
          intéressé
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { usersCollection } from "../main.js"
import firebase from "firebase/compat/app";

export default {
    props : {
        dialog : Boolean,
        connect : Function
    },
    methods: {
         async validWithoutInterest() {
      const userAuth = firebase.auth().currentUser.uid;
      await usersCollection.doc(userAuth).update({
        wantInfo: false,
      });
      this.connect();
    },
    async validInterest() {
      const userAuth = firebase.auth().currentUser.uid;
      await usersCollection.doc(userAuth).update({
        wantInfo: true,
      });
      this.connect();
    },
    }
}
</script>