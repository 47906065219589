<template>
  <div>
    <div class="title">
      <h3>Commandes terminées</h3>
    </div>
    <v-dialog
      v-model="dialogOrder"
      persistent
      min-width="500"
      max-width="850"
    >
      <v-card>
        <v-row class="initialiseMargin">
          <v-card-title class="text-h5">
            Récapitulatif commande
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-title> Aide : 07 56 80 80 21 </v-card-title>
        </v-row>
        <v-row v-if="this.order && checkAdmin()" class="my-3">
          <v-col>
            <v-card height="180" class="text-center ml-4" >
              <div>
                <h2 class="text-subtitle-1 pb-5">{{ getUserName() }}</h2>
                <p class="text-body-1 black--text text-lowercase">{{ this.order.userAddress }}
                  <br>{{ this.order.userPostalCode }}, {{ this.order.userCity }}
                  <br>{{ this.order.userEmail }}
                  <br>{{ this.order.userPhone }}
                </p>
              </div>
            </v-card>
          </v-col>
          <v-col>
            <v-card height="180" class="text-center mr-4">
              <div>
                <h2 class="text-subtitle-1 pb-5">{{this.order.pharmacyName}}</h2>
                <p class="text-body-1 black--text text-lowercase">{{ this.order.pharmacyAddress }}
                  <br>{{ this.order.pharmacyPostalCode }}, {{ this.order.pharmacyCity }}
                  <br>{{ this.order.pharmacyEmail }}
                  <br>{{ this.order.pharmacyPhone }}
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-card-text>
          <v-list>
            <div>
              Produit supprimé : <v-icon small color="red">mdi-marker</v-icon>
            </div>

            <div class="mx-auto text-center text-h6 mb-5" v-if="!checkAdmin()">
              {{ getUserName() }}
            </div>

            <template>
              <v-list-item
                v-for="item in getProducts()"
                :key="item.ean"
                :class="colorChange(item)"
              >
                <template>
                  <v-list-item-content>
                  <v-img
                    :src="item.img != null && item.img !== '' ? item.img
                      : 'https://www.idfmoteurs.com/images/pas-image-disponible.png'
                    "
                    :alt="item.name"
                    contain    
                    width="100"
                    height="100"
                  ></v-img>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title
                      style="white-space: initial"
                    >{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      EAN : {{ item.ean }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Quantité : {{ item.quantity }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      PU rem. TTC : {{ item.price }}€
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Sous-total TTC : {{ item.price * item.quantity }}€
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-divider
                v-if="index < getProducts().length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogOrder = false">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    

    <v-dialog v-model="dialogDelete" max-width="550px">
          <v-card>
            <v-card-title class="text-h5"
              >Attention, la commande va être supprimée !</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#ff0000" text @click="closeDelete">Annuler</v-btn>
              <v-btn color="#015C53" text @click="deleteObj">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

    <v-data-table
      :headers="headers"
      :items="orderSommary"
      sort-by="retrieveDate"
      :sort-desc="true"
      class="elevation-1 row-min-height-80"
      no-data-text="Aucun commande terminée"
      :search="search"
    >
    
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-spacer></v-spacer>
            <v-col>
              <v-text-field
                v-if="checkAdmin()"
                v-model="search"
                class="mx-4"
                label="Rechercher"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="1">
              <div color="primary" dark class="mb-2">
                {{ orderSommary.length }} commandes
              </div>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="() => openOrderDialog(item)"
          v-if="checkOwner(item) || checkAdmin"
        >
          mdi-file-document-edit-outline
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
          v-if="checkAdmin"
        >
          mdi-trash-can-outline
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar
      v-if="message"
      v-model="snackbar"
      absolute
      bottom
      color="#015C53"
      >{{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { checkOwner } from "./arrayData/functionArrayData";
import { ordersCollection, ordersRemovedCollection, logsCollection } from "../main.js";
import moment from "moment";
import firebase from "firebase/compat/app";
import "animate.css";
export default {
  data: () => ({
    dialogDelete: false,
    dialogOrder: false,
    search: "",
    uidAdmin: process.env.VUE_APP_UID_ADMIN,
    id: "",
    snackbar: false,
    message: null,
    orderSommary: [],
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    headers() {
      let headers = [
        {
          text: "Numéro",
          align: "center",
          value: "orderID",
          sortable: true,
        },
        {
          text: "Acheteur",
          align: "center",
          value: "name",
        },
        {
          text: "PT TTC",
          value: "totalPrice",
          align: "center",
          sortable: true,
        },
        {
          text: "Date de retrait",
          value: "retrieveDate",
          align: "center",
          sortable: true,
        },
        { text: "Action", value: "actions", align: "center" },
      ];
      if (localStorage.getItem("uid") === this.uidAdmin) {
        headers.splice(2, 0, 
          {
            text: "Email de l'utilisateur",
            align: "center",
            value: "userEmail",
          },
        );
      }
      return headers;
    },
  },
  mounted() {        
    ordersCollection
      .where("status", "==", "completed")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          let data = doc.data();
          let checkAccess =
            data.products[0].supplier == localStorage.getItem("uid") ||
            this.checkAdmin();

          if (checkAccess) {
            let order = {
              docId: doc.id,
              status: data.status,
              orderID: data.orderID,

              name:
                  data.user.userName ??
                  `${data.user.firstname} ${data.user.lastname}`,
              userEmail:
                data.user.userEmail,
              userAddress: data.user.userAddress,
              userPostalCode: data.user.userPostalCode,
              userCity: data.user.userCity,
              userPhone: data.user.userPhone,

              products: data.products,
              totalPrice: data.totalPrice ?? data.products
                .reduce((acc, product) => {
                  return acc + product.price;
                }, 0)
                .toFixed(2),
              
              pharmacyName: data.pharmacyName,
              pharmacyAddress: data.pharmacyAddress,
              pharmacyPostalCode: data.pharmacyPostalCode,
              pharmacyCity: data.pharmacyCity,
              pharmacyEmail: data.pharmacyEmail,
              pharmacyPhone: data.pharmacyPhone,

              retrieveDate: data.timestamp,
            };

            this.orderSommary.push(order);
          }
        });
      });
  },

  methods: {
    colorChange(item) {
      if (item.canceled == true) {
        return "red lighten-3";
      } else return "green lighten-3";
    },
    checkOwner: checkOwner,

    checkAdmin() {
      let valResult = this.uidAdmin === localStorage.getItem("uid");
      return valResult;
    },
    getUserName() {
      if (this.order != null) {
        let result = this.order["name"];
        return result;
      } else return "";
    },
    getProducts() {
      if (this.order != null) {
        return this.order.products;
      } else return [];
    },
    deleteItem(item) {
      this.editedIndex = this.orderSommary.indexOf(item);
      this.order = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.product = {};
        this.editedIndex = -1;
      });
    },
    async deleteObj() {
      this.order["reason"] = "deleted";
      this.order['created_at'] = firebase.firestore.FieldValue.serverTimestamp();
      this.order['timestamp'] = firebase.firestore.FieldValue.serverTimestamp();
      
      await logsCollection.add({
        event: "delete",
        order: this.order,
        datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
        uid: localStorage.getItem("uid"),
      });
      ordersRemovedCollection.doc(this.order.docId).set(this.order).then(() => {
        ordersCollection
          .doc(this.order.docId)
          .delete()
          .then(() => {
            this.message = "Commande supprimée";
            this.snackbar = true;
            this.orderSommary.splice(this.editedIndex, 1);
            this.closeDelete();
          })
          .catch(() => {
            this.message = "Erreur lors de la suppression de la commande";
            this.snackbar = true;
          });
      });
    },
    openOrderDialog(order) {
      this.order = order;
      this.dialogOrder = true;
    },
  },
};
</script>
<style scoped>
.title {
  text-align: center;
  margin-top: 2rem;
}
h3 {
  margin-top: 2rem;
  color: #015c53;
}
a {
  text-decoration: none;
}
</style>