<template>
  <div class="container-bg">
      <div>
          <div class="container-content">

              <div class="payment">
                  <h1>Accès non autorisé</h1>
                  <p></p>
                  <button @click="redirectToStripe">Bientôt disponible</button>

              </div>

          </div>
      </div>
  </div>
</template>

<script>

import { loadStripe } from '@stripe/stripe-js';
import firebase from "firebase/compat/app";

export default {

data() {
  return {
    amount: 30, // MODIFER ICI PAR LE MONTANT DE LA SOUSCRIPTION A LA FONCTIONNALITE "CIBLAGE"
    stripe: null,
    message: '',
  };
},

async mounted() {
  this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_SECRET_KEY);
},

methods: {
  async redirectToStripe() {
    try {
      const user = firebase.auth().currentUser;
      // POUR LA PRODUCTION
      // const response = await fetch('http://http://cloud.keabot.fr:3000/targeting/create-checkout-session', {
        // POUR TESTER EN LOCAL
        const response = await fetch('http://localhost:3000/targeting/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: this.amount, uid: user.uid }),
      });
      const session = await response.json();
      const { error } = await this.stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (error) {
        this.message = error.message;
      }
    } catch (error) {
      this.message = error.message;
    }
  }
},
};  
</script>

<style scoped>

.payment {
  height: 92vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#card-element {
border: 1px solid #ccc;
padding: 10px;
border-radius: 4px;
}

</style>