import Vue from 'vue'
import Hotjar from 'vue-hotjar'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './routes'
import { getAnalytics } from "firebase/analytics";
import firebase from "firebase/compat/app";
import { firestorePlugin } from 'vuefire';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import "firebase/compat/auth";
import * as VueGoogleMaps from 'vue2-google-maps';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';


Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCmkxrltpeidNrM9lb3SThRLBqQ-sJcwb8',
    libraries: 'places',
  },
});

Vue.use(vuetify);
Vue.use(Toast, {});
Vue.use(firestorePlugin);
Vue.use(Hotjar, {
  id: '2916428' // Hotjar Site ID
});

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
};

const firebaseMobileConfig = {
  apiKey: process.env.VUE_APP_API_KEY_MOBILE,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN_MOBILE,
  databaseURL: process.env.VUE_APP_DATABASE_URL_MOBILE,
  projectId: process.env.VUE_APP_PROJECT_ID_MOBILE,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET_MOBILE,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID_MOBILE,
  appId: process.env.VUE_APP_APP_ID_MOBILE,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
};


let app;
if (!firebase.apps.length) {
  app = firebase.initializeApp(firebaseConfig);
} else {
  app = firebase.app(); 
}
const db = app.firestore();

let appMobile;
if (!firebase.apps.some(app => app.name === "secondary")) {
  appMobile = firebase.initializeApp(firebaseMobileConfig, "secondary");
} else {
  appMobile = firebase.app("secondary"); 
}
const dbMobile = appMobile.firestore();


export const productsCollection = db.collection("products");
export const productsTestCollection = db.collection("productsTest");
export const productsDonatedCollection = db.collection("productsDonated");
export const productsRemovedCollection = db.collection("productsRemoved");
export const productsGlossaryCollection = db.collection("productsGlossary");
export const logsCollection = db.collection("logs");
export const ordersCollection = db.collection("orders");
export const ordersRemovedCollection = db.collection("ordersRemoved");
export const usersCollection = db.collection("users");
export const billsCollection = db.collection("bills");
export const donationsCollection = db.collection("donations");
export const brandsCollection = db.collection("usersSales");

export const usersMobileSideCollection = dbMobile.collection("users");
export const productsMobileSideCollection = dbMobile.collection("products");
export const productsSearchMobileSideCollection = dbMobile.collection("productsSearch");
export const pharmacies = dbMobile.collection("pharmacies");

export {dbMobile};


export const storageRef = app.storage().ref();
const analytics = getAnalytics(app);

new Vue({
  vuetify,
  router,
  analytics,
  render: (h) => h(App),
}).$mount("#app");
