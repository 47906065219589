<template>

<div class="container-bg">
<div>
<div class="container-content">

  <div class="listeDons">

    <div class="search rounded-pill mdi mdi-magnify">
      <input type="text" v-model="search" placeholder="Rechercher">
    </div>

      <div class="top-content">

        <div class="tabs">
  <button
    v-for="(tab, index) in tabs"
    :key="index"
    :class="['tab rounded-pill', { active: activeTab === index, inactive: activeTab !== index }]"
    @click="changeTab(index)"
  >
    {{ tab.title }}
  </button>

  <div class="rounded-pill status" v-if="activeTab !== 1 && activeTab !== 2">
    <button
      @mouseover="statusHover = true"
      @mouseleave="statusHover = false"
      @click="statusFilter = !statusFilter"
    >
      Statuts <i class="mdi mdi-plus"></i>
    </button>

    <div class="status-hover" v-show="statusHover">
      La publication de vos invendus correspond à la mise en ligne sur notre application mobile disponible sur Apple Store et Google Play Store.
    </div>

    <div class="status-filter" v-show="statusFilter">
      <div>Filtrer par statut</div>

      <v-select v-if="activeTab === 0"
        rounded
        class="rounded-pill no-divider"
        v-model="filterStatus"
        placeholder="Status"
        :items="statusItems"
      ></v-select>

      <v-select v-else
        rounded
        class="rounded-pill no-divider"
        v-model="filterStatusPrepared"
        placeholder="Status"
        :items="statusItemsInProgress"
      ></v-select>

                <button class="rounded-pill appliquer" @click="statusFilter = false" >Appliquer</button>

            </div>

          </div>

        
          <v-dialog v-model="dialog" max-width="70%">
          

            <v-card  id="dialogInvendu">
                <v-form v-model="valid" ref="form" lazy-validation width="70%">
                <div class="top-card">

                    <div class="left-card">

                        <div class="card-title">Fiche invendu</div>

                        <div class="detail-product">

                            <div class="codeEan">

                                <v-text-field
                                v-model="product.ean"
                                class="v-text-field--outlined"
                                label="Code EAN"
                                type="text"
                                @keypress="isNumber($event)"
                                placeholder="0000000000000"
                                :rules="eanRules"
                                :disabled="shouldDisable(product)"
                                ></v-text-field>
                    

                                <v-btn 
                                class="validerEan"
                                @click="testEan" 
                                v-if="product.productState != 'verified' || product.productState != 'online'">
                                Valider 
                                </v-btn>

                            </div>

                    <v-text-field
                        rounded
                        class="rounded-pill champs mr-8"
                        v-model="product.name"
                        label="Nom du produit"
                        counter
                        maxlength="41"
                        placeholder="Nom du produit"
                        type="text"
                        :rules="nameRules"
                        :disabled="shouldDisable(product)"
                    ></v-text-field>

                    <v-text-field
                        rounded
                        class="rounded-pill champs"
                        v-model.number="product.quantity"
                        label="Quantité"
                        type="number"
                        @keypress="isNumber($event)"
                        placeholder="0"
                        :rules="quantityRules"
                        :disabled="product.productState ==='expiredSoon'"
                    ></v-text-field>
                    
                    <v-text-field
                        rounded
                        class="rounded-pill champs mr-8"
                        v-model="product.brandName"
                        label="Nom de la marque"
                        type="text"
                        placeholder="Nom de la marque"
                        counter
                        maxlength="28"
                        :rules="brandNameRules"
                        :disabled="shouldDisable(product)"
                    ></v-text-field>

                        <v-text-field
                        rounded
                        class="rounded-pill champs"
                        v-model.number="product.oldPrice"
                        label=" PU TTC"
                        type="text"
                        @keypress="isDecimal($event)"
                        placeholder="9.99"
                        :rules="oldPriceRules"
                        :disabled="shouldDisable(product)"
                        ></v-text-field>

                        <v-select
                        rounded
                        class="rounded-pill champs mr-8"
                        v-model="product.categoryProduct"
                        :items="categoryItems"
                        label="Catégorie"
                        :rules="neededRule"
                        :disabled="shouldDisable(product)"
                        ></v-select>
                    
                          <v-select
                          rounded
                          class="rounded-pill champs"
                          v-model="product.pourcentageReduction"
                          :items="pourcentageItems"
                          label="% de réduction"
                          :rules="pourcentageReductionRules"
                          :disabled="product.productState ==='expiredSoon'"
                          ></v-select>
                

                          <div>              
                            <img
                            @click="showPopup"
                            class="help"
                            src="../../assets/help.png"
                            />
                          </div>

                        <v-text-field
                        rounded
                        class="rounded-pill champs mr-8"
                        v-model="product.packageId"
                        label="Numéro de lot"
                        type="text"
                        placeholder="Numéro de lot"
                        :rules="neededRule"
                        :disabled="shouldDisable(product)"
                        ></v-text-field>

                        <v-text-field
                        rounded
                        class="rounded-pill champs"
                        :value="getNewPrice"
                        label="PU rem. TTC"
                        type="text"
                        :disabled="true"
                        ></v-text-field>

                    </div>

                    <v-checkbox
                    v-model="product.damaged"
                    class="mt-8"
                    label="Packaging abimé"
                    type="checkbox"
                    :disabled="product.productState ==='expiredSoon'"
                    ></v-checkbox>


                    <div class="date">
                        
                        <v-checkbox
                        v-model="product.ddm"
                        label="Date de préférence (DDM)"
                        type="checkbox"
                        :disabled="shouldDisable(product)"
                        ></v-checkbox>

                      <div class="mr-12">
                        <img
                        class="help"
                        @click="showPopupDDM"
                        src="../../assets/help.png"
                        />
                      </div>

                        <div class="mt-5"
                        v-show="product.ddm"
                        :rules="expirationDateRules"
                        >
                        <datepicker
                            v-model="product.ddmSaisi"
                            :language="fr"
                            ref="programaticOpenDDM" 
                            placeholder="Selectionner une date" 
                            :minimumView="'month'" 
                            :maximumView="'month'"
                            :disabled="shouldDisable(product) || !product.ddm">
                            <div slot="beforeCalendarHeader" class="calender-header">
                            Date de préférence
                            </div>
                            <v-icon slot="afterDateInput"
                            small
                            @click="() => openPickerDDM()"
                            >
                            mdi-calendar
                            </v-icon>
                        </datepicker>
                        </div>

                    </div>
                    
                    


                    <div class="date">

                        <v-checkbox
                        v-model="product.expiration"
                        label="Date d'expiration"
                        type="checkbox"
                        :disabled="shouldDisable(product)"
                        ></v-checkbox>

                      <div class="mr-12">
                        <img
                        class="help"
                        @click="showPopupEXP"
                        src="../../assets/help.png"
                        />
                      </div>

                        <div class="mt-5"
                        v-show="product.expiration"
                        :rules="expirationDateRules"
                        >
                        <datepicker
                            v-model="product.expirationSaisi"
                            :language="fr"
                            ref="programaticOpen" 
                            placeholder="Selectionner une date" 
                            :minimumView="'month'" 
                            :maximumView="'month'"
                            :disabled="shouldDisable(product) || !product.expiration"
                            >
                            <div slot="beforeCalendarHeader" class="calender-header">
                            Date d'expiration
                            </div>
                            <v-icon slot="afterDateInput"
                            small
                            @click="() => openPicker()"
                            >
                            mdi-calendar
                            </v-icon>
                        </datepicker>
                        </div>

                    </div>

                        
                </div>

                    
                <div class="right-card">

                    <v-btn class="closeDialog mdi mdi-close" @click="close">
                        <span class="mdi mdi-close"></span>
                    </v-btn>

                    <div class="card-img">
                        <v-img
                        @click="openLink(product.imgUrl)"
                        :src="
                            product.imgUrl ? product.imgUrl : 'https://www.idfmoteurs.com/images/pas-image-disponible.png'
                        "
                        class="rounded-circle borderImg"
                        :alt="product.name"
                        width="150"
                        height="150"
                        ></v-img>

                    </div>

                    <v-file-input
                        rounded
                        class="addImg mb-6"
                        type="file"
                        label="Photo du produit"
                        show-size
                        truncate-length="15"
                        v-model="product.img"
                        accept="image/png"
                        :disabled="shouldDisable(product)"
                    ></v-file-input>
                    
                    <v-select
                        rounded
                        class="rounded-pill"
                        v-model="product.shape"
                        placeholder="Forme"
                        :items="shapeItems"
                        :rules="neededRule"
                        :disabled="shouldDisable(product)"
                    ></v-select>
                    
                    
                    
                    <v-text-field
                        rounded
                        class="rounded-pill champs"
                        v-model="product.capacity"
                        label="Contenance"
                        type="text"
                        @keypress="isDecimal($event)"
                        placeholder="50"
                        :rules="capacityRules"
                        :disabled="shouldDisable(product)"
                        ></v-text-field>
                                    
                    
                    <v-select
                    rounded
                        class="rounded-pill"
                        v-model="product.contenanceType"
                        placeholder="Type"
                        :items="contenanceSelectItems"
                        :rules="neededRule"
                        :disabled="shouldDisable(product)"
                    ></v-select>
                    

                    
                    <v-select
                    rounded
                    class="rounded-pill"
                    v-model="product.contenantType"
                    placeholder="Contenant"
                    :items="contenantItems"
                    :rules="neededRule"
                    :disabled="shouldDisable(product)"
                    ></v-select>
                    
                    
                    
                    <v-select
                    rounded
                        class="rounded-pill"
                        v-model="product.presentation"
                        placeholder="Présentation"
                        :items="presentationItems"
                        :rules="neededRule"
                        :disabled="shouldDisable(product)"
                    ></v-select>
                    
                    
                    </div>

                </div>

                <div class="bottom-card">

                <v-textarea
                    outlined
                    :disabled="shouldDisable(product)"
                    name="description"
                    label="Description"
                    v-model="product.description"
                    auto-grow
                    row-height="1"
                ></v-textarea>

                <v-textarea
                    outlined
                    :disabled="shouldDisable(product)"
                    name="wayToUse"
                    label="Conseils d'utilisation"
                    v-model="product.wayToUse"
                    auto-grow
                    row-height="1"
                ></v-textarea>

                <v-textarea
                    :disabled="shouldDisable(product)"
                    v-if="product.categoryProduct == 'Santé'"
                    outlined
                    name="precautions"
                    label="Précautions d’emploi"
                    v-model="product.precautions"
                    auto-grow
                    row-height="1"
                ></v-textarea>

                <v-textarea
                    outlined
                    :disabled="shouldDisable(product)"
                    name="ingredients"
                    label="Liste des ingrédients"
                    v-model="product.ingredients"
                    auto-grow
                    row-height="1"
                ></v-textarea>

                <v-col cols="12"
                    v-if="product.productState != 'expiredSoon'">
                    <v-checkbox
                    v-model="noMedic"
                    label="J'atteste que le produit n'est ni un médicament remboursable, ni un dispositif médical de classe IIb ou III remboursable."
                    type="checkbox"
                    :rules="neededRule"
                    ></v-checkbox>
                </v-col>

                <v-row>
                    <v-col cols="8">
                    <v-textarea
                        outlined
                        name="remarks"
                        label="Remarques (facultatif)"
                        v-model="product.remarks"
                        auto-grow
                        row-height="1"
                        :disabled="shouldDisable(product)"
                    ></v-textarea>
                    
                    </v-col>
                    <div>
                      <img
                          class="help rem"
                          src="../../assets/help.png"
                          @mouseover="remarquesHover = true"
                          @mouseleave="remarquesHover = false"
                          />
                    </div>

                        <div class="remarques-hover" v-show="remarquesHover">
                        Informations utilisées par notre équipe afin de bien identifier les différentes caractéristiques du produit.
                        </div>
                </v-row>

                </div>
                </v-form>
                        
                <v-card-actions class="ms-14 me-14 pb-8 pt-8">
                    <v-btn
                    text
                    v-if="
                        checkAdminOrTest() &&
                        (
                        product.productState == 'expiredSoon'
                        )
                    "
                    @click="sendOnline">
                        Publier
                    </v-btn>

                    <v-btn
                    text
                    @click="openDialogDonner"
                    v-if="isAvailableToDonate(product)">
                        Donner
                    </v-btn>
                    
                    <v-col cols="2">
                        <v-text-field
                            v-if="checkAdminOrTest()  && 
                            product.productState == 'expiredSoon'"
                            v-model="product.donationDate"
                            label="Date de retrait par l'association"
                            type="date"
                            :rules="donationDateRules"
                        >
                        </v-text-field>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-btn
                    text
                    @click="save"
                    v-if="
                        (editedIndex == -1 ||
                        (editedIndex != -1 && checkOwner(product))) &&
                        (product.productState == 'online') 
                        || (checkAdminOrTest() && product.productState)
                    "
                    >
                        Modifier
                    </v-btn>

                    <v-btn
                    class="vendre rounded-pill"
                    text
                    @click="save"
                    v-if="
                        (editedIndex == -1 ||
                        (editedIndex != -1 && checkOwner(product))) &&
                        (product.productState != 'online' &&
                        product.productState != 'expiredSoon')
                    "
                    >
                        Vendre
                    </v-btn>

                    <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="green"
                    ></v-progress-circular>

                </v-card-actions>

                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Attention, le produit va être supprimé !</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="#ff0000" text @click="closeDelete">Annuler</v-btn>
                      <v-btn color="#0C5C53" text @click="deleteObj">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>

                  </v-card>
                </v-dialog>

                <v-dialog
                  v-model="dialogPourcentage"
                  min-width="500px"
                  min-height="500px"
                >
                  <v-card
                    ><v-card-text
                      ><v-row style="padding-top: 20px; align-items: center"
                        ><v-col
                          cols="6"
                          style="border-right: 1px solid #e0e0e0;"
                        >
                          <br />La loi Européenne de modernisation de l’économie de
                          2008, indique que toute société doit lutter contre la
                          péremption de ses stocks.<br /><br />
                          Elle autorise le déstockage, jusqu’à présent qualifié de
                          soldes déguisés, tout au long de l’année et permet dans ce
                          cadre
                          <b>la revente à perte</b> des produits suivants :<br /><br />
                          <ul>
                            <li>
                              Les produits périssables dont
                              <b>la date limite de vente arrive à échéance</b> peuvent
                              être revendus à perte (exemple : date courte où il reste 3
                              mois avant l’expiration).<br /><br />
                            </li>
                            <li>
                              C’est le cas pour les produits qui se vendent surtout
                              <b>pendant une seule saison</b>, qui peuvent être vendus à
                              perte lorsque celle-ci se termine (exemple : sur-stock de
                              type pulvérisateur anti-moustique en hiver).
                            </li>
                          </ul>
                          <br />
                          <i
                            >Réf. : Loi n° 2008-776 du 4 août 2008, de modernisation de
                            l'économie</i
                          ></v-col
                        >
                        <v-divider vertical></v-divider>
                        <v-col cols="6"
                          ><img
                            src="../../assets/stock_comments.png"
                            style="max-width: 100%"
                        /></v-col> </v-row></v-card-text></v-card
                ></v-dialog>

                <v-dialog v-model="dialogDDM" width="500">
                  <img src="../../assets/dateDDM.png" />
                </v-dialog>

                <v-dialog v-model="dialogExpiration" width="500">
                  <img src="../../assets/dateEXP.png" />
                </v-dialog>

                
              </v-card>
              

          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title class="text-h5"
                >Attention, le produit va être supprimé !</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#ff0000" text @click="closeDelete">Annuler</v-btn>
                <v-btn color="#0C5C53" text @click="deleteObj">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>

            </v-card>
          </v-dialog>
        
        </div>

      </div>

      <v-dialog
          v-model="dialogOrderEnded"
          min-width="800"
          max-width="1000"
        >

          <v-card v-if='this.order'>
            <v-row class="initialiseMargin">
              <v-card-title class="cardTitle">
                Récapitulatif commande n°{{ this.order.orderID}}
              </v-card-title>
            </v-row>
            <v-row v-if="this.order" class="my-3">
              <v-col>
                <v-card height="180" class="infosSales text-center ml-16 mr-2" >
                  <div>
                    <h2 class="text-subtitle-1 pb-5">{{ getUserName() }}</h2>
                    <p class="text-body-1 black--text text-lowercase" v-if="checkAdmin()">{{ this.order.userAddress }}
                      <br>{{ this.order.userPostalCode }} {{ this.order.userCity }}
                      <br>{{ this.order.userEmail }}
                      <br>{{ this.order.userPhone }}
                    </p>
                  </div>
                </v-card>
              </v-col>
              <v-col>
                <v-card height="180" class="infosSales text-center mr-16 ml-2">
                  <div>
                    <h2 class="text-subtitle-1 pb-5">{{this.order.pharmacyName}}</h2>
                    <p class="text-body-1 black--text text-lowercase">{{ this.order.pharmacyAddress }}
                      <br>{{ this.order.pharmacyPostalCode }} {{ this.order.pharmacyCity }}
                      <br>{{ this.order.pharmacyEmail }}
                      <br>{{ this.order.pharmacyPhone }}
                    </p>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-card-text>
              
              <template>
                <v-list>
                  <template>
                    <v-divider></v-divider>
                    <div  v-for="(item) in getProducts()" :key="item.ean">
                      <v-list-item
                      :class="colorChange(item)">
                        <div class="imgDialog">
                          <v-img
                            class="rounded-circle borderImg"
                            :src="item.img != null && item.img !== '' ? item.img : 'https://www.idfmoteurs.com/images/pas-image-disponible.png'"
                            :alt="item.name"
                            contain
                            width="100"
                            height="100"
                          ></v-img>
                        </div>
                        <v-list-item-content>
                          <v-list-item-title style="white-space: initial">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            EAN : {{ item.ean }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            Quantité : {{ item.quantity }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            PU rem. TTC : {{ item.price }}€
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Sous-total TTC : {{ item.price * item.quantity }}€
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </div>
                  </template>
                </v-list>
              </template>
              
              
            </v-card-text>
            <v-card-actions>
              <span class="pb-4 ml-4 cardBottom">PT rem. TTC : {{  this.order.totalPrice }}€</span>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>

      </v-dialog>

      <v-dialog
      v-model="dialogOrder"
      min-width="800"
      max-width="1000"
    >
      <v-card v-if="this.order">
        <v-row class="initialiseMargin">
          <v-card-title class="dialogTitle">
            Récapitulatif commande n°{{ this.order.orderID }}
          </v-card-title>
        </v-row>
        <v-row v-if="this.order && checkAdmin()" class="my-3">
          <v-col>
            <v-card class="ml-4 infoCard" >
              <div>
                <h4>{{ getUserName() }}</h4>
                <p v-if="checkAdmin()">{{ this.order.userAddress }}
                  <br>{{ this.order.userPostalCode }} {{ this.order.userCity }}
                  <br>{{ this.order.userEmail }}
                  <br>{{ this.order.userPhone }}
                </p>
              </div>
            </v-card>
          </v-col>
          <v-col>
            <v-card class="mr-4 infoCard">
              <div>
                <h4>{{this.order.pharmacyName}}</h4>
                <p>{{ this.order.pharmacyAddress }}
                  <br>{{ this.order.pharmacyPostalCode }} {{ this.order.pharmacyCity }}
                  <br>{{ this.order.pharmacyEmail }}
                  <br>{{ this.order.pharmacyPhone }}
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <div class="mx-auto text-center text-h6 mb-5" v-if="!checkAdmin()">
          {{ getUserName() }}
        </div>
        
        <v-card-text>
          <v-list>
            <v-divider></v-divider>
            <template v-for="(item, index) in getProducts()">
              <v-list-item 
              :key="item.ean"
              :class="colorChange(item)">
                <template>
                      <div class="imgDialog">
                          <v-img
                            class="rounded-circle borderImg"
                            :src="item.img != null && item.img !== '' ? item.img : 'https://www.idfmoteurs.com/images/pas-image-disponible.png'"
                            :alt="item.name"
                            contain
                            width="100"
                            height="100"
                          ></v-img>
                        </div>
                  <v-list-item-content>
                    <v-list-item-title
                      style="white-space: initial"
                    >{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      EAN : {{ item.ean }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Quantité : {{ item.quantity }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      PU rem. TTC : {{ item.price }}€
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Sous-total TTC : {{ item.price * item.quantity }}€
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn v-if="order.status == 'validated'" text small @click="report(item)" :disabled="item.canceled">
                      Corriger le stock
                    </v-btn>
                  </v-list-item-action>
                </template>
              </v-list-item>

              <v-divider
                v-if="index < getProducts().length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <span class="pb-4 ml-4 cardBottom">PT rem. TTC : {{  this.order.totalPrice }}€</span>
          <v-spacer></v-spacer>
          <v-btn class="validerEan" @click="dialogConfirm = true">
            <div v-if="this.order && this.order.status == 'validated'">
              Préparer
            </div>
            <div v-if="this.order && this.order.status == 'prepared'">
              Valider
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogReport" max-width="500px">
      <v-card class="dialogSante">
        <v-card-title class="text-h5 red lighten-2">
          Annuler la commande d'un produit
        </v-card-title>
        <v-card-text class="text-center pa-12">
          En confirmant, le produit sera retiré de la commande et le
          remboursement s'effectuera sous 48H
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#0C5C53" text @click="closeDialogReport">Annuler</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="confirmReport"
            >Retirer le produit</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirm" max-width="500px">
      <v-card class="dialogOrder">
        <div v-if="this.order && this.order.status == 'prepared'">
          <v-card-text class="text-center pa-12">
            Confirmez-vous le retrait de la commande par le client ?
          </v-card-text>
        </div>
        <div v-else>
          <v-card-text class="text-center pa-12">
            Confirmez-vous la préparation de la commande ?
          </v-card-text>          
          <v-card-text class="text-center pa-12">
            En confirmant, un e-mail sera envoyé au client afin de retirer sa commande
          </v-card-text>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogConfirm = false"
            ><span class="capitalize">Annuler</span></v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="validOrder" class="rounded-pill confirm"
          ><span class="capitalize">Confirmer</span></v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
      
      <div class="data">

          <div v-if="activeTab === 0" class="tableau">

              <div class="tableau-title">
                  <div class="name">Invendu</div>
                  <div class="marque">Marque</div>
                  <div>Stock</div>
                  <div>Date d'exp.</div>
                  <div>PU rem. TTC</div>
                  <div>Action</div>
              </div>
              <div>
                  <div class="invendus" v-for="(product, index) in paginatedProducts" :key="index" 
                  :class="{
                    'publie': product.productState === 'online',
                    'nonPublie': product.productState !== 'online'
                  }">

                    <div class="basic-infos">
                        <div class="seeMore">
                            <span :class="['mdi', {'mdi-chevron-right': seeMoreIndex != index}, {'mdi-chevron-down': seeMoreIndex === index}, 'open']"
                            @click="showInfos(index)"></span>
                        </div>                                
                            <div class="name">
                                {{ product.ean }} <br>
                                {{ product.name }}
                            </div>
                            <div class="marque">
                                {{ product.brandName }}
                            </div>
                            <div>
                                {{ product.quantity }}
                            </div>
                            <div>
                                {{ product.expirationDate }}
                            </div>
                            <div>
                                {{ formatPrice(product.price) }} €
                            </div>
                            <div>
                            <v-icon
                              small
                              class="mr-2"
                              @click="editItem(product)"
                              v-if="
                                (checkOwner(product) || checkAdminOrTest)
                              "
                            >
                              mdi-file-document-edit-outline
                            </v-icon>
                            <v-icon
                              small
                              class="mr-2"
                              @click="editItem(product)"
                              v-if="
                                (checkOwner(product) || checkAdminOrTest) &&
                              product.productState == 'unverified'
                              "
                            >
                              mdi-file-document-edit-outline
                            </v-icon>
                            </div>
                      </div>


                      <div>
                          <table class="supp-infos" v-show="seeMoreIndex === index">
                              <thead>
                                  <tr>
                                      <th>Catégorie</th>
                                      <th>N° lot</th>
                                      <th class="packaging">Packaging abimé</th>
                                      <th>Remise</th>
                                      <th v-show="checkAdmin()">E-mail vendeur</th>
                                      <th v-show="checkAdmin()">Ville</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td>{{ product.categoryProduct }}</td>
                                      <td>{{ product.packageId }}</td>
                                      <td class="packaging-td">
                                        <input type="checkbox" v-model="product.damaged" disabled>
                                      </td>
                                      <td>{{ product.pourcentageReduction }}%</td>
                                      <td v-show="checkAdmin()">{{ product.mail }}</td>
                                      <td v-show="checkAdmin()">{{ product.city }}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>

          </div>

          <div class="tableau" v-else-if="activeTab === 1">

              <div class="tableau-title">
                  <div class="bis">Numéro</div>
                  <div class="bis">Acheteur</div>
                  <div v-show="checkAdmin()" class="pharma">Vendeur</div>
                  <div>PT rem. TTC</div>
                  <div class="bis">Date commande</div>
                  <div>Action</div>
              </div>
              <div class="historique" v-for="(order, index) in filteredOrders" :key="index"
              :class="{
                    'publie': order.status === 'prepared',
                    'nonPublie': order.status !== 'prepared'}"
                    >
                <div class="bis">{{order.orderID}}</div>
                <div class="bis">{{order.name}}</div>
                <div v-show="checkAdmin()" class="pharma">{{ order.pharmacyName }}</div>
                <div>{{ formatPrice(order.totalPrice) }} €</div>
                <div class="bis">{{ formatDate(order.date) }}</div>
                <div>
                  <v-icon
                    small
                    class="mr-2"
                    @click="openOrderDialog(order)"
                    v-if="checkOwner(order) || checkAdmin"
                  >
                    mdi-file-document-edit-outline
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(order)"
                    v-if="checkAdmin"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </div>

              </div>

          </div>

          <div class="tableau" v-else>

              <div class="tableau-title">
                  <div class="bis">Numéro</div>
                  <div class="bis">Acheteur</div>
                  <div class="pharma" v-show="checkAdmin()">Vendeur</div>
                  <div class="">PT rem. TTC</div>
                  <div class="bis">Date retrait</div>
                  <div class="">Action</div>
              </div>
              <div class="historique" v-for="(order, index) in filteredOrders" :key="index">
                    <div class="bis">{{order.orderID}}</div>
                    <div class="bis">{{order.name}}</div>
                    <div class="pharma">{{order.pharmacyName}}</div>
                    <div class="basic">{{formatPrice(order.totalPrice)}} €</div>
                    <div class="bis">{{formatDate(order.retrieveDate)}}</div>
                    <div>
                      <v-icon
                        small
                        class="mr-2"
                        @click="openOrderDialogEnded(order)"
                        v-if="checkOwner(order) || checkAdmin"
                      >
                        mdi-file-document-edit-outline
                      </v-icon>
                      <v-icon
                        small
                        @click="deleteItem(order)"
                        v-if="checkAdmin"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </div>
                </div>

          </div>

      </div>

      <div class="nav-pages" v-if="activeTab === 0">
          <div>{{ products.length }} invendus</div>
          <div id="pagination-container">
              <button class="mdi mdi-chevron-double-left" @click="currentPage = 1" :disabled="currentPage === 1"></button>
              <button class="mdi mdi-chevron-left" @click="prevPage" :disabled="currentPage === 1"></button>
              <button class="mdi mdi-chevron-right" @click="nextPage" :disabled="currentPage === totalPages"></button>
              <button class="mdi mdi-chevron-double-right" @click="currentPage = totalPages" :disabled="currentPage === totalPages"></button>
          </div>
          <div class="select-nb">
            <span>Produits par page</span>
            <button class="nb-products" @click="itemsPerPage = 5">5</button>
            <button class="nb-products" @click="itemsPerPage = 10">10</button>
            <button class="nb-products" @click="itemsPerPage = 15">15</button>
            <button class="nb-products" @click="itemsPerPage = products.length">Tous</button>
          </div>
      </div>

      <div class="nav-pages" v-else-if="activeTab === 1">
          <div>{{ orders.length }} commande(s) en cours</div>
      </div>

      <div class="nav-pages" v-else>
          <div>{{ orderSommary.length }} commande(s) terminées</div>
      </div>


  </div>

</div>

<footer class="footer-style">
          v2.4.1
        </footer>
</div>
</div>

</template>

  
<script>
import {fr} from 'vuejs-datepicker/dist/locale';
import { ordersCollection, ordersRemovedCollection, logsCollection } from "../../main.js";
import moment from "moment";
import {
  getDateColor,
  isNumber,
  getDataFromEan,
  checkOwner,
  isDecimal,

  donateProduct,
  sendOnline,
  sendObject,
  deleteObj,
  getProductsList,
  updateObject,
  manageDonation,
} from "./../arrayData/functionArrayData";
import "firebase/compat/database";
import firebase from "firebase/compat/app";
import "animate.css";

export default {

    data: () => ({
    statusFilter: false,
    statusHover: false,
    remarquesHover: false,
    filterStatus: "all",
    filterStatusPrepared:"all",
    seeMoreIndex: 0,
    quantityDon: 0,
    currentPage: 1,
    itemsPerPage: 5,
    tab: null,
    activeTab : 0,
    tabs: [
        { title: 'Liste'},
        { title: 'En cours'},
        { title: 'Historique'}
    ],
    fr: fr,
    disabled: 0,
    loading: false,
    search: "",
    noMedic: false,
    checkTraceability: false,
    valid: false,
    uidAdmin: process.env.VUE_APP_UID_ADMIN,
    uidTest: process.env.VUE_APP_UID_TEST,
    uidDemo: process.env.VUE_APP_UID_DEMO,
    statusItems: ["Tous", "Publié", "Non publié"],
    id: "",
    snackbar: false,
    isUploading: false,
    message: null,
    dialog: false,
    dialogDelete: false,
    dialogSante: false,
    dialogDonner: false,
    dialogDDM: false,
    dialogExpiration: false,
    dialogPourcentage: false,
    dialogDonation: false,
    dialogOrder: false,
    dialogOrderEnded: false,
    dialogReport: false,
    dialogConfirm: false,
    products: [],
    productsFiltered: [],
    orders: [],
    orderSommary: [],
    order: null,
    productsExpiredSoon: [],
    editedIndex: -1,
    product: {},
    contenanceSelectItems: [
  "g",
  "mL",
  "L",
  "ampoules",
  "capsules",
  "comprimés à avaler",
  "comprimés à croquer",
  "comprimés effervescents",
  "comprimés orodispersibles",
  "gélules",
  "gommes",
  "pastilles",
  "sachets",
  "tampons",
  "unidoses",
  "unité(s)",
],
contenantItems: [
  "Aluminium",
  "Carton",
  "Céramique",
  "Papier",
  "Plastique non recyclé",
  "Plastique recyclé",
  "Tissu",
  "Verre",
  "N/A",
],
pourcentageItems: [30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80],
categoryItems: [
  "Aromathérapie",
  "Bébé",
  "Cheveux",
  "Compléments alimentaires",
  "Cosmétiques",
  "Homme",
  "Hygiène",
  "Maquillage et accessoires",
  "Minceur",
  "Phytothérapie",
  "Santé",
  "Sexualité",
  "Sport",
  "Vétérinaire",
],
shapeItems: [
  "Aérosol",
  "Baume",
  "Biphase",
  "Crème",
  "Fluide",
  "Gel",
  "Gouttes",
  "Huile",
  "Lait",
  "Liquide",
  "Lotion",
  "Masque",
  "Mousse",
  "Pain",
  "Poudre",
  "Pâte",
  "Sirop",
  "Solide",
  "Solution micellaire",
  "Sérum",
  "Vernis",
],
presentationItems: [
  "Boîte",
  "Brosse",
  "Coffret",
  "Flacon",
  "Flacon pompe",
  "Kit",
  "Lot",
  "Lingette",
  "Patch",
  "Pipette",
  "Plaquette",
  "Pot",
  "Recharge",
  "Roll-on",
  "Sachet",
  "Spray",
  "Stick",
  "Stylo",
  "Tube",
  "Tube pompe",
],
nameRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) =>
    (v && v.length <= 41) ||
    "Le nom du produit doit comporter moins de 42 caractères",
],
eanRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) =>
    (v && v.length <= 13) ||
    "Le code EAN doit comporter moins de 14 caractères",
  (v) => /^\d+$/.test(v) || "seuls des chiffres entiers sont autorisés",
],
quantityRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) => /^\d+$/.test(v) || "seuls des chiffres entiers sont autorisés",
],
brandNameRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) =>
    (v && v.length <= 28) ||
    "Le nom de la marque doit comporter moins de 29 caractères",
],
pourcentageReductionRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) =>
    (v >= 30 && v <= 80) ||
    "Le pourcentage de réduction doit être compris entre 30 et 80",
  // v => /^\d+$/.test(v)||'seuls des chiffres entiers sont autorisés',
],
neededRule: [(v) => !!v || "Ce champ est obligatoire"],
oldPriceRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) =>
    /^\d*\.?\d+$/.test(v) || "seuls des nombres (avec '.') sont autorisés",
],
capacityRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) =>
    /^\d*\.?,?\d+$/.test(v) || "seuls des chiffres entiers sont autorisés",
],
expirationDateRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) =>
    (v && v.length <= 10) ||
    "La date d'expiration doit comporter moins de 8 caractères",
],
donationDateRules: [
  (v) => !!v || "Ce champ est obligatoire",
  (v) =>
    (v && v.length <= 10) ||
    "La date de donnation doit comporter moins de 8 caractères",
],

  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  computed: {
    totalPages() {
        return Math.ceil(this.products.length / this.itemsPerPage);
    },
    paginatedProducts() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      const term = this.search.toLowerCase();
      if (this.filterStatus == 'all' || this.filterStatus == 'Tous')
      {
        if (term == '')
          return this.products.slice(startIndex, endIndex);
        else
          return this.products.filter(product => {
            return product.name.toLowerCase().includes(term)
            || product.brandName.toLowerCase().includes(term)
            || product.categoryProduct.toLowerCase().includes(term)
        }).slice(startIndex, endIndex)
      }
      else {
        if (this.filterStatus == "Publié")
          return this.products.filter(product => product.productState == "online").slice(startIndex, endIndex);
        else
          return this.products.filter(product => product.productState != "online").slice(startIndex, endIndex);
      }
    },
    filteredOrders() {
      const term = this.search.toLowerCase();
      if (this.filterStatusPrepared == 'all' || this.filterStatusPrepared == 'Tous')
      {
        if (term == '')
          return this.activeTab === 1 ? this.orders : this.orderSommary;
        else
        {
          return this.activeTab == 1 ?
          this.orders.filter(order => {
              return order.orderID.toLowerCase().includes(term)
              || order.name.toLowerCase().includes(term)
              || order.pharmacyName.toLowerCase().includes(term)
          })
          : this.orderSommary.filter(order => {
              return order.orderID.toLowerCase().includes(term)
              || order.name.toLowerCase().includes(term)
              || order.pharmacyName.toLowerCase().includes(term)
          })
        }

      }
      else {
        if (this.filterStatusPrepared == "Préparée")
          return this.orders.filter(order => order.status == "prepared")
        else
          return this.orders.filter(order => order.status != "prepared");
      }
    },
    getNewPrice: function () {
      if (
        this.product["pourcentageReduction"] !== undefined &&
        this.product["oldPrice"] !== undefined
      ) {
        return Number(
          (
            (this.product.oldPrice *
              (100 - this.product["pourcentageReduction"])) /
            100
          ).toFixed(2)
        );
      } else {
        return 0;
      }
    },
  },

  mounted() {
    this.getProductsList();
    this.getOrdersInProgress();
    ordersCollection
      .where("status", "==", "completed")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          let data = doc.data();
          let checkAccess =
            data.products[0].supplier == localStorage.getItem("uid") ||
            this.checkAdmin();

          if (checkAccess) {
            let order = {
              docId: doc.id,
              status: data.status,
              orderID: data.orderID,

              name:
                  data.user.userName ??
                  `${data.user.firstname} ${data.user.lastname}`,
              userEmail:
                data.user.userEmail,
              userAddress: data.user.userAddress,
              userPostalCode: data.user.userPostalCode,
              userCity: data.user.userCity,
              userPhone: data.user.userPhone,

              products: data.products,
              totalPrice: data.totalPrice ?? data.products
                .reduce((acc, product) => {
                  return acc + product.price;
                }, 0)
                .toFixed(2),
              
              pharmacyName: data.pharmacyName,
              pharmacyAddress: data.pharmacyAddress,
              pharmacyPostalCode: data.pharmacyPostalCode,
              pharmacyCity: data.pharmacyCity,
              pharmacyEmail: data.pharmacyEmail,
              pharmacyPhone: data.pharmacyPhone,

              retrieveDate: data.timestamp,
            };

            this.orderSommary.push(order);
          }
        });
      });
  },

  methods: {
    notValidForm() {
    this.message = "remplissage du formulaire incorrect, demande non envoyée";
    this.snackbar = true;
  },
    formatPrice(price) {
    const floatPrice = parseFloat(price);
    if (isNaN(floatPrice)) {
        return "Prix invalide";
    }
    return floatPrice.toFixed(2).replace('.', ',');
},
  prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
  nextPage() {
      if (this.currentPage < this.totalPages) {
          this.currentPage++;
      }
  },
    async getOrdersInProgress() {
      ordersCollection
        .where("status", "!=", "completed")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            let data = doc.data();
            let checkAccess =
              data.supplier == localStorage.getItem("uid") ||
              this.checkAdmin();
            if (
              checkAccess &&
              (data.status == "validated" || data.status == "prepared")
            ) {
              let order = {
                docId: doc.id,
                status: data.status,
                orderID: data.orderID,

                name:
                  data.user.userName ??
                  `${data.user.firstname} ${data.user.lastname}`,
                userEmail:
                  data.user.userEmail,
                userAddress: data.user.userAddress,
                userPostalCode: data.user.userPostalCode,
                userCity: data.user.userCity,
                userPhone: data.user.userPhone,

                products: data.products,
                totalPrice: data.totalPrice ?? data.products
                  .reduce((acc, product) => {
                    return acc + product.price;
                  }, 0)
                  .toFixed(2),
                
                pharmacyName: data.pharmacyName,
                pharmacyAddress: data.pharmacyAddress,
                pharmacyPostalCode: data.pharmacyPostalCode,
                pharmacyCity: data.pharmacyCity,
                pharmacyEmail: data.pharmacyEmail,
                pharmacyPhone: data.pharmacyPhone,

                date: data.created_at
                  ? moment
                      .unix(data.created_at["seconds"])
                      .format("YYYY/MM/DD HH:mm:ss")
                  : data.date,
              };

              this.orders.push(order);
            }
          });
        });
    },
    showInfos(index) {
        if (this.seeMoreIndex === index)
            this.seeMoreIndex = -1;
        else
            this.seeMoreIndex = index;
    },
    changeTab(index) {
        this.activeTab = index;
        this.search = "";
        this.filterStatus = "all";
        this.statusFilter = false;
    },
    formatDate(value) {
        if (value != undefined)
          return moment(value).format("DD/MM/YY")
    },
    getDateColor: getDateColor,
    isNumber: isNumber,
    checkOwner: checkOwner,
    isDecimal: isDecimal,
    donateProduct: donateProduct,
    manageDonation: manageDonation,
    sendOnline: sendOnline,
    deleteObj: deleteObj,
    getProductsList: getProductsList,
    updateObject: updateObject,
    sendObject: sendObject,
    async testEan() {
      let product = await getDataFromEan(this.product["ean"]);
      this.product = Object.assign({}, product);
    },
    openPicker() {
      this.$refs.programaticOpen.showCalendar();
    },
    openPickerDDM() {
      this.$refs.programaticOpenDDM.showCalendar();
    },
    openLink(url) {
      window.open(url);
    },
    checkAdmin() {
      return (this.uidAdmin === localStorage.getItem("uid"));
    },
    checkAdminOrTest() {
      let valResult =
        localStorage.getItem("uid") === this.uidAdmin || localStorage.getItem("uid") === this.uidTest
      return valResult;
    },
    isAvailableToDonate(product) {
      return (product.productState === 'online');
    },
    shouldDisable(product) {
      return (product.productState === 'online' || product.productState === 'expiredSoon') && 
      (localStorage.getItem("uid") !== this.uidAdmin && localStorage.getItem("uid") !== this.uidTest);
    },
    editItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.product = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.product = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.product = {};
        this.editedIndex = -1;
      });
    },
    manageDonationDialog() {
      this.manageDonation(this.products);
      this.dialogDonation = false;
    },
    addDonation() {
      this.donateProduct(this.editedIndex)
      .then(() => {
        this.dialog = false;
      });
    },
    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.isUploading = false;
      this.$nextTick(() => {
        this.product = {};
        this.editedIndex = -1;
      });
    },
    openDialogDonner() {
    this.dialogDonner = true;
  },
    save() {
      if (this.valid) {
        if (this.editedIndex > -1) {
          this.loading = true;
          this.updateObject();
        } else if (this.editedIndex === -1) {
          this.loading = true;
          this.sendObject();
        }
      } else {
        this.message =
          "remplissage du formulaire incorrect, demande non envoyée";
        this.snackbar = true;
      }
    },
    confirmReport() {
      this.cancelProduct(this.productToReport).then(() => {
        this.closeDialogReport();
      });
    },
    closeDialogReport() {
      this.productToReport = null;
      this.dialogConfirm = false;
      this.dialogReport = false;
      this.dialogOrder = false;
      this.dialog = false;
      this.orders = [];
      this.getOrdersInProgress();
    },
    async validOrder() {
      if (this.order.status == "validated") {
        await ordersCollection.doc(this.order.docId).update({
          status: "prepared",
          timestamp: moment().format("YYYY/MM/DD HH:mm:ss"),
        });
        this.order.status = "prepared";
      } else {
        await ordersCollection.doc(this.order.docId).update({
          status: "completed",
          timestamp: moment().format("YYYY/MM/DD HH:mm:ss")
        });
        this.orders.splice(this.order.docId, 1);
      }

      this.order = null;
      this.dialogConfirm = false;
      this.dialogOrder = false;
    },
    showPopup() {
      this.dialogDDM = false;
      this.dialogExpiration = false;
      this.dialogPourcentage = true;
    },
    showPopupDDM() {
      this.dialogPourcentage = false;
      this.dialogExpiration = false;
      this.dialogDDM = true;
    },
    showPopupEXP() {
      this.dialogDDM = false;
      this.dialogPourcentage = false;
      this.dialogExpiration = true;
    },
    getFile() {
      if(this.uidAdmin === localStorage.getItem("uid")) {
        let fileUpload = document.getElementById('fileUpload')
        if (fileUpload != null) {
          fileUpload.click()
        }
      }
    },
    report(product) {
      this.productToReport = product;
      this.dialogReport = true;
    },
    colorChange(item) {
      if (item.canceled == true) {
        return "nonPublie";
      } else return "publie";
    },
    getUserName() {
      if (this.order != null) {
        let result = this.order["name"];
        return result;
      } else return "";
    },
    getProducts() {
      if (this.order != null) {
        return this.order.products;
      } else return [];
    },
    openOrderDialog(order) {
      this.order = Object.assign({}, order);
      this.dialogOrder = true;
    },
    openOrderDialogEnded(order) {
      this.order = Object.assign({}, order);
      this.dialogOrderEnded = true;
    },
    async deleteCom() {
  this.order["reason"] = "deleted";
  this.order['created_at'] = firebase.firestore.FieldValue.serverTimestamp();
  this.order['timestamp'] = firebase.firestore.FieldValue.serverTimestamp();
  
  await logsCollection.add({
    event: "delete",
    order: this.order,
    datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
    uid: localStorage.getItem("uid"),
  });
  ordersRemovedCollection.doc(this.order.docId).set(this.order).then(() => {
    ordersCollection
      .doc(this.order.docId)
      .delete()
      .then(() => {
        this.message = "Commande supprimée";
        this.snackbar = true;
        this.orderSommary.splice(this.editedIndex, 1);
        this.closeDelete();
      })
      .catch(() => {
        this.message = "Erreur lors de la suppression de la commande";
        this.snackbar = true;
      });
  });
}
  },
}
</script>

<style scoped>

.top-content {
    padding: 1% 2%;
}

.tab {
    padding: 0.5% 3%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
    margin-right: 2vw;
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 0px 10px;
}

.tab.active {
    background-color: #B5E5DC;
    color: #015c53;
}

.tab.inactive {
    background-color: #FFFFFF;
    color: #3d3c3c;
}

.rounded-pill.status {
  margin: 10px 0 0px 10px;
}

.status {
    border: 2px dashed grey;
    color: grey;
    padding: 0.5% 3%;
    font-size: 20px;
    font-weight: bold;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
    margin-left: 15vw;
}

.ajouter-don {
    padding: 0.5% 3%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
    margin-left: 6vw;
    color: #015c53;
    background-color: #FDD82A;
    font-size: 20px;
    font-weight: bold;
}

.data {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.tableau {
    margin-top: 1.5vh;
    width: 95%;
    height: auto;
    border: 2px solid rgba(149, 149, 149, 0.501);
    border-radius: 20px;
}

.tableau-title {
    padding: 1% 4%;
    display: flex;
    border: 1px solid rgba(149, 149, 149, 0.501);
    border-radius: 17px;
    width: 100%;
    height: 6vh;
}

.tableau-title div {
    font-weight: bold;
    color: grey;
    font-size: 20px;
}

.invendus {
    padding: 1% 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 18px;
}

.invendus {
    border-bottom: 1px solid lightgrey;
}

.invendus:last-of-type {
  border: none;
}

.basic-infos {
    display: flex;
}

.mdi-chevron-right {
  background-color: #FFFFFF;
}

.mdi-chevron-down {
  background-color: #FFFFFF;
}

.more-infos {
    margin-left: 2%;
    margin-right: 1%;
}

.name {
    width: 20vw !important;
}

.marque {
    width: 20vw !important;
}

.seeMore {
    width: 4% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardTitle {
  color: rgba(0, 0, 0, 0.5);

}

.open {
    padding: 2%;
    border-radius: 5px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
}

.top-card {
  display: flex;
}

.bottom-card {
  padding: 0 2%;
  display: flex;
  flex-direction: column;
}

.left-card {
  width: 60%;
  padding: 2%;
}

.card-title {
  color: grey;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 4vh;
}


.right-card {
  margin-top: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}

.right-card .v-select, .right-card input {
  border: 1px solid grey;
  padding: 1% 2%;
  width: 80% !important;
  height: 4vh !important;
  flex: none;
  margin-bottom: 3vh;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
}

.right-card input {
  padding-left: 8%;
}

.card-img {
  margin: 3vh 0;
}

.bis {
  width: 12vw !important;
}

.historique {
  display: flex;
  border-bottom: 1px solid lightgrey;
  padding: 1% 0;
    width: 100%;
    font-size: 18px;
}

.publie {
  background-color: rgba(181, 229, 220, 0.2)
}

.nonPublie {
  background-color: rgba(253, 216, 42, 0.2);
}

.infosSales {
  border-radius: 30px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328) !important;
}

.infosSales div p, .infosSales div h2 {
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: 900 !important;
}

.imgDialog {
  margin: 2vh 2vw;
  width: auto;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328) !important;
}

.cardBottom {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
  font-size: 16px;
}

.pharma {
  width: 20vw !important;
}

.basic {
  width: 8vw;
}

.dialogTitle {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
}

.v-dialog .v-card {
  padding: 2vh 2vw !important;
}

.dialogOrder {
  display: flex;
  color: grey;
  font-weight: bold;
  flex-direction: column;
  text-align: center;
  gap: 3vh;
  padding: 2% !important;
  border-radius: 20px;
  border: 3px solid #015c53
}

.validerEan {
  text-transform: none;
  font-weight: bold;
}

.dialogOrder .v-card__text {
  font-size: 16px;
  font-weight: bold;
  padding: 2% 6% !important;
}

.dialogOrder .confirm {
  background-color: #FDD82A;
  padding: 0 2vh;
}

.infoCard {
  min-height: 18vh !important;
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: bold !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328) !important;
}

.infoCard p {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-h5 {
  color: #3d3c3c;
  font-size: 10px;
}

.v-list-item__action .v-btn--text {
  text-transform: none !important;
  color : rgba(1, 92, 83, 1) !important;
  font-size: 16px;
}

.capitalize {
    text-transform: lowercase;
    font-weight: bold;
    color: #0C5C53;
}

.capitalize::first-letter {
    text-transform: uppercase;
}

@media screen and (max-width: 1350px) {
  .search {
    width: 18vw;
    top: 22px;
  }

  .tableau-title div {
    font-size: 10px;
  }

  .invendus {
    font-size: 10px;
  }

  .nav-pages * {
    font-size: 10px;
  }

  .data {
    height: 75% !important;
  }

  .tableau th {
    font-size: 14px;
  }

  .select-nb {
    width: 30%;
  }

  .historique {
    font-size: 10px;
  }

  .tab, .status {
    font-size: 12px;
    height: 5vh;
  }
}

</style>