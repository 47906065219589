<template>
    <div class="container-bg">
        <div>
            <div class="container-content">

                <div class="payment">
                    <h1>Paiement réussi</h1>
                    <button class="rounded-pill" @click="redirect">Accéder à la fonctionnalité</button>


                </div>

            </div>
        </div>
    </div>
  </template>
  
  <script>
import firebase from "firebase/compat/app";
  
  export default {

    async mounted() {
      await this.checkAuthState();
    },

    methods: {

      redirect() {
        this.$router.push('/targeting');
      },

      async checkAuthState() {
        await new Promise(resolve => {
          const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
          });
        });
      },

    }

  };
  </script>

<style scoped>

.payment {
    height: 92vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

button {
  padding: 1% 3%;
  margin-top: 2vh;
  height: 5vh !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
  color: #015c53;
  background-color: #FDD82A;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center !important;
}


</style>