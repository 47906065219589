<template>
    <div>
      <ul class="tabs__header">
        <li
            v-for="(tab, index) in tabs"
            :key="tab.title"
            @click="selectTab(index)"
            :class='{"tab__selected": (index == selectedIndex)}'
            >
            {{ tab.title }}
        </li>
      </ul>
      <slot></slot>
    </div>
  </template>
  
  <script>
    export default {
      data () {
        return {
          selectedIndex: 0, // the index of the selected tab,
          tabs: []         // all of the tabs
        }
      },
      created () {
        this.tabs = this.$children
      },
      mounted () {
        this.selectTab(0)
      },
      methods: {
        selectTab (i) {
            this.selectedIndex = i

            // loop over all the tabs
            this.tabs.forEach((tab, index) => {
            tab.isActive = (index === i)
            })
        }
      }
    }
  </script>
  
<style scoped lang="css">
    ul.tabs__header {
    list-style: none;
    margin-right: 2vw;
    padding: 0;
    display: flex;
    justify-content: left;
    padding: 0.5% 3%;
    }

    ul.tabs__header > li {
    background-color: #FFFFFF;
    box-shadow: 1px 3px 1px #D9D9D9;
    color: #3d3c3c;
    font-weight: bold;
    padding: 5px 30px;
    border-radius: 50px;
    display: inline-block;
    margin: 15px 0 10px 20px; /* top left bottom right */
    cursor: pointer;
    font-size: 12px;
    height: 5vh;
    }

    ul.tabs__header > li.tab__selected {
    background-color: #B5E5DC;
    color: #015c53;
    font-weight: bold;
    font-size: 12px;
    height: 5vh;
    }

    .tab {
    display: inline-block;
    color: #3d3c3c;
    padding: 20px;
    min-width: 800px;
    border-radius: 10px;
    min-height: 400px;
    font-size: 10px
    }
</style>