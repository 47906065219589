<template>
  <div class="top-icone" v-if="loggedIn">
        <!-- <div class="top-button-container">
          <div class="top-button mdi mdi-bell-ring-outline"></div>
        </div> -->
        <v-menu bottom offset-y>
            <template v-slot:activator="{ on }">
              <div class="top-button-container">
                <div :elevation="0" v-on="on" class="top-button mdi mdi-account-circle">
                </div>
              </div>
            </template>
            <v-list class="list-style">
              <v-list-item>
                    <router-link class="router-link-style" to="/account">Mes informations personnelles</router-link>
              </v-list-item>
              <v-list-item>
                <a class="router-link-style" href="https://partenaire.keabot.fr/o1_ywqd7aggx_pwo3qpm-cnjpbhqthpllr7imnyris1yft" target="_blank">
                  Nos partenaires
                </a>
              </v-list-item>
              <v-list-item>
                <router-link class="router-link-style" to="/facturation">Facturation</router-link>
              </v-list-item>
              <v-list-item>
                <router-link class="router-link-style" to="/about">A propos</router-link>
              </v-list-item>
              <v-list-item>
                <a class="router-link-style" href="https://partenaire.keabot.fr/aide/" target="_blank">Aide</a>
              </v-list-item>
              <v-list-item>
                <router-link class="router-link-style" @click.native="signOut" to="/login">Déconnexion</router-link>
              </v-list-item>
            </v-list>
        </v-menu>
  </div>
</template>  

<script>
import firebase from "firebase/compat/app";
export default {
  data() {
    return {
      error: false,
      loggedIn: false,
    };
  },
  mounted() {
    this.loggedIn = localStorage.getItem("loggedIn");
    this.setupFirebase();
  },
  methods: {
    setupFirebase() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          localStorage.setItem("loggedIn", true);
          localStorage.setItem("uid", user.uid);
          this.loggedIn = true;
        } else {
          localStorage.setItem("loggedIn", false);
          localStorage.setItem("uid", "");
          this.loggedIn = false;
        }
      });
    },
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.setItem("loggedIn", false);
          localStorage.setItem("uid", "");
          this.$router.push("/login");
        })
        .catch((err) => {
          console.log(err);
          alert(
            "Une erreur est survenue lors de la connection, merci de joindre le support technique"
          );
        });
    },
  },
};
</script>

<style>

a {
  text-decoration: none !important;
}

.top-icone {
    background-color: #B5E5DC;
    padding-top: 4vh;
    padding-right: 4vw;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5vw;
    z-index: 30;
}

.top-button-container {
    display: flex !important;
    margin: 0 !important;
    padding: 0 !important;
    justify-content: center !important;
    align-items: center !important;
    height: 70px;
    width: 70px;
}

.top-button {
    background-color: white;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    font-size: 40px !important;
    padding: 0 !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
    cursor: pointer;
}

.list-style * {
z-index: 50;
  color: black !important;
}

@media screen and (max-width: 1300px) {

.top-button-container {
  height: 40px;
  width: 40px;
}

.top-button {
  font-size: 30px !important;
}

}

</style>