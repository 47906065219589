<template>
  <div class="login-container">
      <router-link class="logo" to="/home">
      <img src="../../assets/logo.png" class="img-logo" />
      </router-link>
      <v-form class="form-style">
        
              <h2 class="connexion-style mb-2" style="text-align: left;">
                Connexion
              </h2>
    
                  <v-text-field
                    v-model="form.email"
                    :rules="emailRules"
                    :label="form.email ? null : 'E-mail'"
                    required
                    class="v-text-field--outlined mb-2"
                  ></v-text-field>
                  <v-text-field
                    v-model="form.password"
                    :rules="passwordRules"
                    :label="form.password ? null : 'Mot de passe'"
                    required
                    class="password-eye v-text-field--outlined"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="togglePasswordVisibility"
                    :type="show ? 'text' : 'password'"
                  ></v-text-field>
              <div class="mt-12 forgotPassword link-small" @click="$router.push('/forgot-password')">
                Mot de passe oublié ?
              </div>
              <v-row v-if="dialog == true">
              <Dialog :connect="connect" dialog/>
              </v-row>
                  <v-btn 
                    class="connect-style" 
                    @click="submit">
                    Se connecter
                  </v-btn>
        <div class="error animate__animated animate__fadeIn" v-if="error">
          {{ error }}
        </div>
        <footer class="footer-style">
          v2.4.1
          <span class="footer-separator">|</span>
          <span ><a target="_blank" class="legalNotices-style" :href="legalNotices"> Mentions légales </a></span>
          <span class="footer-separator">|</span>
          <span><a target="_blank" class="privacyPolicy-style" :href="privacyPolicy"> Politique de confidentialité </a></span>
        </footer>
      </v-form>
  </div>
</template>

<script>
import Dialog from "../dialog.vue"
import firebase from "firebase/compat/app";
import { usersCollection } from "../../main.js";
import "animate.css";
export default {
  components : {
    Dialog,
  },
  data() {
  return {
    legalNotices: "https://partenaire.keabot.fr/mentions-legales/",
    privacyPolicy: "https://partenaire.keabot.fr/politique-de-confidentialite/",
    form: {
      email: "",
      password: "",
    },
    show: false,
    dialog: false,
    loggedIn: false,
    emailRules: [
      (v) => !!v || "E-mail obligatoire",
      (v) => /.+@.+\..+/.test(v) || "E-mail invalide",
    ],
    passwordRules: [
      (v) => !!v || "Mot de passe obligatoire",
      // v => v.length >= 6 || 'Mot de passe doit contenir au moins 6 caractères',
    ],
    error: null,
    buttonText: "Se connecter",
  };
},

  methods: {
    connect() {
      localStorage.setItem("loggedIn", true);
      this.dialog = false;
      this.loggedIn = true;
      this.$router.push("/home");
    },
   
    async checkUserPropalWithPopUp() {
      const userAuth = firebase.auth().currentUser.uid;
      const user = await usersCollection.doc(userAuth).get();
      if (user.data().wantInfo == undefined || user.data().wantInfo == false) {
        this.dialog = true;
      } else {
        this.connect();
      }
    },

    togglePasswordVisibility() {
      this.show = !this.show;
    },

    async submit() {
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(this.form.email, this.form.password);
        this.checkUserPropalWithPopUp();
        localStorage.setItem("loggedIn", true);
      } catch (err) {
        setTimeout(() => {
          this.error = null;
        }, 2800);
        this.error = "E-mail ou mot de passe incorrect";
      }
    },
  },
};
</script>


<style scoped>

.logo {
  padding: 1%;
}

.logo img {
  width: 10%;
  display: block;
}

.login-container {
  display: flex;
  flex-direction: column;
  background-color: #B5E5DC;
  height: 100vh;
}

.form-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;
  width: 100vw;
  padding-top: 20vh;
}

p {
  text-align: center;
}

.connexion-style {
  color: rgba(0, 0, 0, 0.66);
  font-size: 18px;
  text-align: left;
}

.email-style {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  display: inline-block;
  padding: 25px;
  width: 380px;
  height: auto;
}

.password-style {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  display: inline-block;
  padding: 25px;
  width: 380px;
  height: 50px;
  border: none;
}

.password-eye >>> .v-icon {
  font-size: 16px;
  margin-top: 30px;
}

.link-small {
  width: auto; 
  display: inline-block;
}

.connect-style {
  background-color: rgba(253, 216, 42, 1) !important;
  color:rgba(1, 92, 83, 1);
  width: 180px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 20px;
  text-transform: none;
  padding: 3vh 6vw !important;
}

.forgotPassword {
  color: rgba(0, 0, 0, 0.66);
  cursor:pointer
}

.v-input {
  margin: 0;
}

.footer-style {
  position: absolute;
  bottom: 0;
  font-size: 10px;
  left: 0;
  width: 100%;
  margin-top: 30px;
  color: #3d3c3c;
}

.footer-style a {
  color: #3d3c3c;
  text-decoration: none;
}

.footer-style a:hover {
  text-decoration: underline;
}

.footer-separator {
  margin: 5px;
}

.legalNotices-style {
  text-decoration: none;
  color:#000000
}

.privacyPolicy-style {
  text-decoration: none;
  color:#000000
}

.error {
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0;
  -webkit-box-shadow: 0 0 10px #FDD82A;
  -moz-box-shadow: 0 0 10px #FDD82A;
}

h2 {
  color: #015c53;
}

.v-input {
  background-color: white;
  width: 20vw;
  border-radius: 40px;
  height: 7vh !important;
  display: flex;
  align-items: center;
  padding: 0 1vw;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

</style>